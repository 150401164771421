// top-bar section
@import "variables", "mixin";

.top-bar {
    &__blocks {
        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        border-bottom: 1px solid #d9e0ec;
    }

    &__block {
    }

    .login {
        margin: 0 32px 0 0;
    }

    .register {
    }
    .receipt-place__button {
        text-decoration: none;
    }
    @include mediaTablet {
        display: none;
    }
}

.city {
    margin: 0 auto 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__button {
        font-size: 16px;
    }
    &__button:hover {
        .button {
            &__ico {
                fill: #D4343C;
            }
        }
    }
    &__button:active {
        .button {
            &__ico {
                fill: #D4343C;
            }
            &__text {
                color: #D4343C;
            }
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__ico {
    }

    &__text {
    }
}

.dop-logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 11px;
    line-height: 1.35;
    color: #FFFFFF;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #299AE5;
    border-radius: 0px 0px 5px 5px;
    padding: 17px 18px 5px;
    
    img {
        max-width: 155px;
        height: auto;
    }

    @include mediaTablet {
        padding: 8px 18px 7px;

        div {
            display: none;
        }
    }

    @include media(620px) {
        display: none;
    }
}

.favor-compare {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 45px 0 0;
    border-right: 1px solid #d9e0ec;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: 0 0 0 35px;
    }
    &__item:first-child {
        margin: 0;
    }
   
    .button__number {
        position: absolute;
        top: -5px;
        right: -10px;
        z-index: 2;
    }
    .button:active .button {
        &__ico {
            fill: #D4343C;
        }

        &__text {
            color: #D4343C;
        }
    }
    .button:hover .button {
        &__ico {
            fill: #D4343C;
        }
    }
}
.login-register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 40px;

     
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.registration {
    position: relative;
}
// END top-bar section

// brand section
.brand {
    &__blocks {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
        padding: 18px 0;

        @include mediaTablet {
            padding: 10px 0;
        }
        @include mediaPhone {
            padding: 8px 0;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #000000;
    }

    &__slogon {
        font-weight: 400;
        font-size: 14px;
        color: #758296;
    }
}

.brand-logo {
    max-width: 220px;

    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__image {
        margin: 0;
        font-size: 0;
    }

    &__text {
    }
    @include mediaTablet {
        max-width: 140px;

        &__text {
            display: none;
        }
    }

    @include mediaPhone {
        max-width: 120px;
    }
}

.brand-phone {
    max-width: calc(100% - 220px);
    padding-left: 7%;
    white-space: nowrap;

    &__ico {
        margin: 0 10px 0 0;
        fill: none;
        stroke: #2D58A4;
        stroke-width: 1.6px;
    }

    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 18px;
        color: #0a182f;
        margin-left: 32%;

        @include mediaTablet {
            margin-left: 30px;
            border: 1px solid #2D58A4;
            border-radius: 5px;
            min-width: 44px;
            width: 44px;
            height: 44px;

            svg {
                margin-right: 0;
            }

            span {
                display: none;
            }
        }

        &:hover {
            .brand-phone {
                &__ico {
                    stroke: #D4343C;
                    fill: none;
                }
            }
        }
    }

    &__mail {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 16px;
        color: #0A182F;

        @include mediaPhone {
            svg {
                margin: 0;
                width: 18px;
                height: 18px;
            }

            span {
                display: none;
            }
        }

        &:hover {
            .brand-phone {
                &__ico {
                    stroke: #D4343C;
                    fill: none;
                }
            }
        }
    }

    .button {
        margin-left: 20px;

        @include media(1140px) {
            display: none;
        }
    }

    @include mediaTablet {
        justify-content: flex-end;

        &__ico {
            width: 16px;
            height: 16px;
        }
        &__number {
            font-size: 16px;
        }
    }

    @include mediaPhone {
        &__number {
            border: 0;
            padding: 0;
            margin-left: 15px;

            span {
                display: none;
            }
        }
    }
}

// END brand section

// nav main and cart section
.navigation {
    border-top: 1px solid #d9e0ec;
    &__blocks {
        display: flex;
        min-height: 60px;
    }

    &__block {
        flex-basis: 100%;
    }
    @include mediaTablet {
        border-top-color: #EEF7FF;

        &__blocks {
            min-height: 44px;
        }
    }
    .container {
        position: relative;
    }
}
.catalog-menu {
    display: flex;
    justify-content: space-between;
    max-width: 177px;
    border-right: 1px solid #d9e0ec;

    &__button {
        width: 100%;
        padding: 0 10px;
        border-radius: 0;
        border: 0;
        text-transform: capitalize;
        &:hover {
            border: 0;
        }
        svg {
            margin: 0 10px 0 0;
            width: 16px;
            height: 24px;

            @include mediaPhone {
                margin: 0 8px 0 0;
            }
        }

        span {
            font-weight: 500;
            font-size: 16px;

            @include mediaPhone {
                font-size: 14px;
            }
        }

        .button__number {
        }
    }

    @include mediaTablet {
        max-width: 157px;
    }
    @include mediaPhone {
        max-width: 155px;
    }
}
.main-menu {
    max-width: 880px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-right: 1px solid #d9e0ec;
    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        list-style: none;
    }
    
    &__sublist {
        position: absolute;
        z-index: 10;
        background-color: #ffffff;
        padding: 12px 0;
        list-style-type: none;
        display: none;
        border: 1px solid #d9e0ec;
        width: 180%;
        .main-menu__link {
            padding: 8px 0;
        }
    }

    &__item {
        margin: 0;
        &:hover {
            .main-menu__sublist {
                display: block;
            }
        }
    }

    &__link, &__link:visited {
        padding: 14px 0;
        margin: 0 20px;
        font-size: 16px;
        font-weight: 500;
        color: #0a182f;
        display: block;
    }
    &__link:hover {
        color: #D4343C;
    }
    &__parent {
        position: relative;
        padding-right: 10px;
        flex-shrink: 0;
        &:after {
            content: '';
            display: block;
            width: 8px;
            height: 4px;
            position: absolute;
            right: 10px;
            bottom: 50%;
            transform: translateY(50%) rotate(0);
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 5px solid #2f2f2f;
            transition: all ease 0.3s;
        }
        &:hover {
            &:after {
                transform: translateY(50%) rotate(180deg);
            }
        }
    }

    @include mediaTablet {
        display: none;
    }
}
.right-menu {
    display: flex;
    justify-content: flex-end;
    flex-basis: auto;
    margin: 0 auto;
    width: 100%;
    max-width: 355px;

    @include media(1140px) {
        max-width: 190px;
    }

    @include mediaTablet {
        margin: 0 0 0 auto;
        max-width: 100%;

        .button__text {
            font-size: 16px;
        }
        .button__number {
            font-size: 8px;
            width: 14px;
            height: 14px;
        }
    }

    @include mediaPhone {
        margin: 0 0 0 auto;
    }

    .city-select {
        display: none;
        padding: 0 14px;
        border-right: 1px solid #d9e0ec;
        border-left: 1px solid #d9e0ec;

        @include mediaTablet {
            display: flex;
            align-items: center;
            border-color: #EEF7FF;
        }
        @include mediaPhone {
            padding: 0 10px;
            min-width: 44px;
        }
    }

    .brand-search {
        width: 100%;
        transition: all ease 0.3s;
        @include mediaPhone {
            display: flex;
            width: 0;
            padding: 0;
            overflow: hidden;
            position: absolute;
            height: 44px;
            left: 0;
            right: 0;
            top: 0;
            background-color: #ffffff;
            z-index: 91;
        }
        &.active {
            @include mediaPhone {
                width: 100%;
                padding: 0 15px;
            }
        }
    }

    .cart {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 20px;
        max-width: 128px;

        @include mediaTablet {
            margin: 0 15px 0 15px;
        }

        .cart-button {
            position: relative;
        }
        .button__ico {
            flex-shrink: 0;
            margin: 0 15px 0 0;
            @include mediaPhone {
                margin: 0 7px 0 0;
            }
        }
        .button__text {
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
            white-space: nowrap;
            color: #0a182f;

            @include mediaPhone {
                display: none;
            }
        }
        .button__number {
            position: absolute;
            left: 4px;
            top: -5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            border-radius: 50px;
            background: #D4343C;
            padding: 0;
            @include mediaTablet {
                left: 2px;
                left: 4px;
                top: -5px;
                width: 12px;
                height: 13px;
                font-size: 8px;
                padding: 8px;
            }
        }

        @include mediaPhone {
            margin: 0;
            padding: 0 8px;
            min-width: 44px;
        }
    }
}
// END nav main and cart section
