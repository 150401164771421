@import 
"variables",
"mixin";
.pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px!important;
    margin: 5px 0 0 0!important;
    border: 1px solid #d9e0ec;
    &__list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 3px;
        margin: 0 15px 0 0;
        font-size: 14px;
        color: #0a182f;

        &:hover {
            color: #0a182f;
        }
    }
    &__item:last-child {
        margin: 0;
    }
    .page {
        margin: 0 10px 0 0;

        &:hover,
        &:focus {
            border: 1px solid #d9e0ec;
        }
    }
    &__item.active,
    &__item:active {
        color: #fff;
        background: #2D58A4;
    }

    .prev {
        margin: 0 15px 0 0;
        svg {
            transform: rotate(90deg);
        }
    }

    .next {
        margin: 0 0 0 5px;
        svg {
            transform: rotate(-90deg);
        }
    }

    .prev,
    .next {
        background: #ffffff;
        border: 1px solid #d9e0ec;
        box-sizing: border-box;
        border-radius: 3px;

        svg {
            width: 10px;
            height: 10px;
            fill: #0a182f;
        }

        &:hover,
        &:focus {
            border: 1px solid #0a182f;
        }

        &.disabled {
            border: none;
            cursor: default;

            &:hover,
            &:focus {
                border: none;
            }
            
            svg {
                fill: grey;
            }
        }
    }

    &__total {
        margin: 0 0 0 auto;
        font-size: 14px;

        color: #758296;
    }
}

@include mediaTablet {
    .pagination {
        padding: 10px;

        .prev {
            margin: 0 10px 0 0;
        }

        .next {
            margin: 0 0 0 10px;
        }
    }
}

@include mediaPhone {
    .pagination {
        .page {
            margin: 0 6px 0 0;
        }

        &__list {
            justify-content: space-between;
            margin: 0 auto;
        }
        &__item {
            height: 26px;
            width: 26px;
        }
        &__total {
            display: none;
        }
        .page {
            margin: 0;
        }
        .prev {
            margin: 0 5px 0 0;
        }

        .next {
            margin: 0 0 0 5px;
        }
    }
}
