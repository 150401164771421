.vendors-section {
    &__min-title {
        font-size: 24px;
        
        @include mediaTablet {
            font-size: 22px;
        }

        @include mediaPhone {
            font-size: 20px;
        }
    }

    .splide {
        &__pagination {
            display: none;

            @include mediaTablet {
                display: flex;
            }
        }
    }
}

.block-vendor {
    &__link {
        display: block;
        width: 100%;
    }

    &__img {
        display: block;
        overflow: hidden;
        background-color: #fff;
        border: 1px solid #D2D7E4;
        border-radius: 5px;
        padding: 20px 26px;
        height: 150px;

        @include mediaPhone {
            padding: 17px 25px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        display: block;
        margin-top: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaPhone {
            margin-top: 10px;
        }
    }

    &__file {
        display: inline-flex;
        align-items: center;
        margin-top: 7px;
        color: #2D58A4;
        text-decoration: underline;
        font-weight: 500;
        font-size: 14px;

        svg {
            width: 16px;
            height: 16px;
            fill: #2D58A4;
            margin-right: 10px;
        }
    }
}

.vendors-page {
    max-width: 100%;

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 30px;

        @include mediaTablet {
            flex-direction: column;
        }

        .button {
            padding-left: 25px;
            padding-right: 25px;
            color: #ffffff;

            @include mediaTablet {
                margin-top: 20px;
            }
        }
    }

    &__filters {
        ul {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 0;

                @include mediaTablet {
                    margin-bottom: 5px;
                }

                a {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.36em;
                    color: #0A182F;
                    text-decoration: none;
                    text-transform: uppercase;

                    @include mediaPhone {
                        font-size: 14px;
                    }

                    &.active {
                        color: #2D58A4;
                    }
                }
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        .block-vendor {
            width: calc(20% - 10px);
            margin: 0 5px 30px 5px;
            
            @include mediaTablet {
                width: calc(33.333% - 10px);
            }

            @include mediaPhone {
                width: calc(50% - 10px);
                margin-bottom: 20px;
            }
        }

        .no-res {
            .button {
                margin-top: 15px;
            }
        }
    }
}

.vendor-page {
    &__header {
        display: flex;
        align-items: center;
        margin-top: 50px;

        @include mediaTablet {
            margin-top: 40px;
        }

        @include mediaPhone {
            margin-top: 30px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__hrader-img {
        background: #FFFFFF;
        border: 1px solid #D2D7E4;
        border-radius: 5px;
        margin-right: 30px;
        width: 150px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mediaPhone {
            margin-right: 0;
            margin-bottom: 12px;
        }

        img {
            max-width: 60%;
            max-height: 60%;
            object-fit: contain;
        }
    }

    &__header-text {
        .section__header {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__header-link {
        display: inline-block;
        margin-top: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        text-decoration-line: underline;
        color: #2D58A4;
    }
}

.vendor__slider .splide__arrow {
    top: calc(50% - 20px);
}
.projects__slider .splide__arrow {
    top: calc(50% - 40px);
}