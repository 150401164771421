@import "variables", "mixin";

.articles {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
    }
    &__item {
    }
    &__dropdowns {
        display: flex;
    }
    &__news-tabs {
        
    }

    &__filter {
        display: flex;
        align-items: center;
        margin: 45px 0 45px 0;
        justify-content: space-between;
        .dropdown {
            margin: 0 30px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    @include mediaTablet {
        &__list {
            flex-direction: column;
            margin-top: 30px;
        }
        &__news-tabs {
            margin-bottom: 20px;
        }
        &__item {
        }
        &__filter {
            margin: 26px 0 31px 0;
            flex-direction: column;
            align-items: inherit;
        }
    }
    @include mediaPhone {
        &__list {
            margin-top: 25px;
        }

        &__filter {
            margin: 23px 0 20px 0;

            .dropdown {
                margin: 0 12px 0 0;
            }
        }
    }
}

.article {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
    border-radius: 5px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9e0ec;

    &:nth-last-child(-n + 2) {
        margin: 0;
    }

    &__image {
        min-width: 150px;
        height: 160px;
        display: block;
        object-fit: cover;
        flex: 0;
        margin: 20px;
        margin-right: 0;
        border-radius: 5px;
    }

    &__text {
        padding: 20px;
        margin: 0 auto 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
    }

    &__text-tags {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 10px 0;
    }

    &__text-date {
        margin: 0 0 7px 0;
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: 14px;
        color: #758296;
    }

    &__text-date-expires {
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 34px;
        line-height: 34px;
        background: #EEF7FF;
        border-radius: 5px;
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: 14px;
        color: #758296;
        &-ico {
            width: 13px;
            height: 18px;
            margin: 0 8px 0 0;
            fill: #758296;
        }
    }

    &__text-sale {
        display: flex;
        align-items: center;
        background: #FFE3C1;
        border-radius: 5px 0px 0px 5px;
        padding: 0 8px;
        height: 34px;
        line-height: 34px;
        font-weight: 500;
        font-size: 14px;
        color: #FFA031;
        margin-right: -20px;

        svg {
            width: 14px;
            height: 14px;
            fill: #FFA031;
            margin-right: 6px;
        }
    }

    &:hover & {
        &__text-title {
            color: #2D58A4;
        }
    }
    &__text-title {
        margin: 0 0 7px 0;
        font-weight: 500;
        font-size: 16px;
        color: #0a182f;
        line-height: 1.5;
    }

    &__text-description {
        margin: 0 0 7px 0;
        font-size: 14px;
        color: #758296;
    }

    &__text-link {
        margin: auto 20px 0 0;
        color: #2D58A4;

        svg {
            fill: #2D58A4;
        }
    }

    @include mediaTablet {
        margin: 0 0 20px 0;
        max-width: initial;

        &:nth-last-child(-n + 2) {
            margin: 0 0 20px 0;
        }
        &:last-child {
            margin: 0;
        }
        &__image {
            // width: initial;
        }

        &__text {
            padding: 20px;
        }

        &__text-date {
            font-size: 14px;
        }
        &__text-date-expires {
            font-size: 14px;
            &-ico {
            }
        }
        &__text-title {
            font-size: 16px;
            line-height: 1.5;
        }

        &__text-description {
        }

        &__text-link {
        }
    }

    @include mediaPhone {
        flex-direction: column;
        margin: 0 0 10px 0;
        padding: 20px;
         
        &:nth-last-child(-n + 2) {
            margin: 0 0 10px 0;
        }

        &__image {
            max-width: 100%;
            width: 100%;
            align-self: flex-start;
            margin: 0;
            height: 150px;
            max-height: 150px;
        }

        &__text {
            padding: 17px 0 0 0;
        }

        &__text-date {
            font-size: 14px;
        }
        &__text-date-expires {
            font-size: 14px;
        }
        &__text-title {
            font-size: 14px;
            margin: 0 0 7px;
        }

        &__text-description {
            margin: 0 0 10px;
        }

        &__text-title,
        &__text-description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &__text-link {
        }
    }
}

.promo.page {
    .articles__list {
        margin: 53px 0 0 0;

        @include mediaTablet {
            margin: 35px 0 0 0;
        }
        @include mediaPhone {
            margin: 30px 0 0 0;
        }
    }
}

.news-tabs {
    display: inline-flex;
    align-items: flex-end;
    border-bottom: 2px solid #D2D7E4;

    &__item {
        position: relative;
        font-weight: 700;
        line-height: 1.4;
        color: #0A182F;
        padding-bottom: 17px;
        margin-right: 50px;
        font-size: 24px;

        @include mediaTablet {
            margin-right: 40px;
            font-size: 18px;
        }

        @include mediaPhone {
            margin-right: 20px;
            padding-bottom: 8px;
        }

        &:last-child {
            margin-right: 0 !important;
        }

        &.is-active {
            color: #2D58A4;
            
            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: #2D58A4;
                position: absolute;
                left: 0;
                bottom: -2px;
                z-index: 1;
            }
        }
    }
}

.news {
    #mse2_filters {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .news-tabs {
            margin-bottom: 12px;
    
            &__item {
                font-size: 24px;
        
                @include mediaPhone {
                    font-size: 22px;
                }
            }
        }
    }
}