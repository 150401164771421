@import "variables", "mixin";
.footer {
    // margin: 85px 0 0 0;
    background: #2D58A4;

    &__main {
        padding: 56px 0 40px 0;

        @include mediaTablet {
            padding: 25px 0;
        }

        @include mediaPhone {
            padding: 20px 0;
        }
    }

    &__blocks {
        display: flex;
        justify-content: flex-start;
    }

    &__block {
        flex-basis: 100%;
    }

    &__brand {
        flex-basis: auto;
        white-space: nowrap;
        a {
            display: flex;
            align-items: center;
            color: #fff;
            margin: 0 0 12px 0;
        }

        @include mediaTablet {
            display: flex;
            flex-basis: 100%;
            justify-content: space-between;
            align-items: center;

            a {
                margin: 0;
            }
        }
    }

    &__brand-logo-image {
        margin: 0 20px 0 0;
        flex-shrink: 0;
        font-size: 0;

        @include mediaTablet {
            max-width: 138px;
        }

        @include mediaPhone {
            min-width: 119px;
        }
    }

    &__brand-logo-text {
        @include mediaPhone {
            display: none;
        }
    }

    &__brand-name {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
    }

    &__brand-slogon {
        font-size: 14px;
        font-weight: 400;
        color: #869ec9;
    }

    &__subscription {
        margin-top: 35px;
        max-width: 228px;
        font-size: 16px;
        line-height: 2;

        @include mediaTablet {
            display: none;
        }
        
        & .sendex_message_descr {
            display: block;
            white-space: initial;
            font-size: 14px;
            line-height: 150%;
            color: #fff;
            margin-top: 10px;
        }
        
        &_subtitle {
            font-size: 12px;
            white-space: normal;
            line-height: 1.2;
        }

        p {
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 10px;
        }

        form {
            width: 100%;
        }

        .input {
            background: #3C69BA;
            border: 1px solid #5883CE;
            border-radius: 5px;
            color: #fff;

            &::placeholder {
                color: #FFFFFF;
            }
        }

        .button {
            width: 100%;
            background: #FFFFFF;
            border-radius: 5px;
            line-height: 50px;
            height: 50px;
            margin-top: 10px;
            font-weight: 500;
            font-size: 16px;
            color: #2D58A4;
            text-transform: none;

            &:hover {
                background: #f9fbff;
            }
        }
    }

    &__appstores {
        display: flex;

        a {
            margin: 0 6px 0 0;

            @include mediaPhone {
                margin: 0 0 6px 0;
            }
        }

        @include mediaPhone {
            flex-direction: column;
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-start;
        flex-basis: 50%;
        margin: 0 0 0 120px;

        @include media(1080px) {
            margin: 0 0 0 30px;
        }

        @include mediaTablet {
            flex-direction: column;
            align-items: flex-end;
        }

        .footer-nav {
            margin: 0 90px 0 0;
            flex: 1;

            &:last-child {
                margin: 0;
            }

            @include media(1080px) {
                margin: 0 30px 0 0;
            }

            .button {
                background-color: #fff;
                font-weight: 500;
                font-size: 16px;
                color: #2D58A4;
                height: 50px;
                line-height: 50px;
                text-transform: none;
                margin-top: 20px;
                max-width: 228px;

                @include mediaTablet {
                    margin-top: 0;
                    min-width: 157px;
                    width: 100%;
                }

                @include mediaPhone {
                    min-width: 118px;
                }

                &:hover {
                    background: #f9fbff;
                }

                svg {
                    margin-right: 10px;
                    width: 16px;
                    height: 24px;
                    fill: #2D58A4;
                }
            }
        }
    }

    &__nav-list {
        // flex-basis: 100%;
        list-style: none;
        padding: 0;

        @include mediaTablet {
            display: none;
        }
    }

    .footer-nav-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 2;
        color: #FFFFFF;
        margin-bottom: 10px;

        @include mediaTablet {
            display: none;
        }
    }

    &__nav-item {
        margin: 0 0 12px 0;
    }
    &__nav-item:last-child {
        margin: 0;
    }

    &__nav-link {
        font-size: 16px;
        color: #fff!important;

        &:hover {
            text-decoration: underline;
            color: #fff;
        }
    }

    &__contacts {
        flex-basis: auto;
        margin: 0 0 0 auto;

        &-phone {
            font-family: $secondaryFont;
            white-space: nowrap;
            margin-bottom: 10px;

            a {
                font-weight: 600;
                font-size: 18px;
                line-height: 1.2;
                color: #FFFFFF;
            }
        }

        p {
            color: #fff;
            white-space: nowrap;
        }

        &-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            color: #98ADD3;
            margin-bottom: 2px;
        }

        .button {
            justify-content: flex-start;
            &__text {
                color: #fff;
            }
            &__ico {
                fill: #fff;
            }

            margin: 12px 0 0 0;
        }

        .button:first-child {
            margin: 0;
        }
        .button.footer_email_link  {
            span {
                text-decoration: underline;
            }
        }

        .button.footer_email_link:hover  {
            span {
                text-decoration: none;
            }
        }

        .button.footer_email_link {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }

        @include mediaTablet {
            display: none;
        }
    }

    &__bottom {
        padding: 21px 0;
        border-top: 1px solid #3C69BA;

        .footer {
            &__blocks {
                align-items: center;

                @include mediaPhone {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
            }
        }
    }

    &__copyright {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include mediaTablet {
            font-size: 12px;
        }

        @include mediaPhone {
            flex-basis: 50%;
        }

        p {
            color: #fff;
        }
    }

    &__socials {
        color: #fff;

        @include mediaPhone {
            flex-basis: 50%;
            justify-content: flex-end;
        }
    }
    &__socials-text {
        @include mediaTablet {
            display: none;
        }
    }

    &__socials-icons {
        .social {
            &__ico {
                width: 14px;
                height: 14px;
                fill: #2D58A4;
            }
        }

        a {
            background: #ffffff;
        }
        a:hover {
            .social {
                &__ico {
                    fill: #D4343C;
                }
            }
        }
        a:last-child {
        }
    }

    span.button {
        cursor: default;
    }

    &__dev {
        text-align: right;

        @include mediaPhone {
            margin-top: 20px;
        }
    }

    .site-dev {
        display: inline-flex;
        align-items: center;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-align: left;

        @include mediaTablet {
            font-size: 12px;
        }

        @include mediaPhone {
            width: 100%;
            text-align: center;
            justify-content: center;
        }

        &__img {
            max-width: 48px;
            height: auto;
            margin-right: 20px;

            @include mediaTablet {
                max-width: 32px;
                margin-right: 12px;
            }
        }

        a {
            display: block;
            color: #fff;
            text-decoration: underline;

            @include mediaPhone {
                display: inline-block;
            }
        }
    }
}
