@import "variables", "mixin";
.product-item {
    &__tabs {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin: 81px -10px 0 -10px;
    }

    &__tab {
        flex: 1;
        position: static;
        margin: 0 10px;
    }

    &__tab-title {
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #758296;
        cursor: pointer;
        padding: 0 10px;
        border: 1px solid #D2D7E4;
        border-radius: 5px;
        height: 44px;
        line-height: 42px;

        svg {
            display: none;
            width: 8px;
            height: 8px;
            fill: #0a182f;
            transform: rotate(90deg);
        }
    }

    &__tab-content {
        position: absolute;
        top: 82px;
        left: 0;
        // max-height: 0;
        width: calc(100% - 20px);
        opacity: 0;
        visibility: hidden;
        margin: 0 10px;
        p {
            margin-bottom: 12px;
        }
        p,
        ul,
        li {
            font-size: 14px;
            line-height: 1.5;

            color: #0a182f;
        }
    }

    &__tab.active & {
        &__tab-title {
            font-weight: 600;
            color: #2D58A4;
            border-color: #2D58A4;
            font-size: 18px;

            svg {
                fill: #2D58A4;
            }
        }

        &__tab-content {
            opacity: 1;
            visibility: visible;
        }
    }

    &__tab:hover &{
        &__tab-title {
            // font-weight: bold;
            color: #2D58A4;
            border-color: #2D58A4;
            svg {
                fill: #2D58A4;
            }
        }
    }
}

.product {
    &__tab-cols {
        display: flex;
    }
    &__tab-col {
        flex: 1;
        margin: 0 20px 0 0;
    }
    &__tab-col:last-child {
        margin: 0;
    }
}

.characteristic-tab {
}

.video-tab {
    .youtube-list {
        display: flex;
        flex-wrap: wrap;

        .youtube-wrapper {
            // flex: 0 1 49%;
            min-width: 240px;
            margin: 0 10px 10px 0;
            padding: 0;
        }

        .youtube-wrapper:last-child {
            margin: 0;
        }

        .youtube {
            margin: 0;
        }
    }
}
.documents-tab {
    .documents-title {
        margin: 0 0 20px 0;
        h4 {
            margin: 0 0 10px 0;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0.03em;
            color: #0a182f;
        }
        a {
            font-size: 14px;
            text-decoration: underline;

            color: #2D58A4;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .document {
        margin: 0 0 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &__image {
            margin: 0 10px 0 0;
        }

        &__link {
            display: flex;
            flex-direction: column;
            line-height: 1.5;
        }

        &__link:hover {
            .document__name {
                text-decoration: underline;
            }
        }

        &__name {
            font-size: 14px;
            color: #0a182f;
        }

        &__size {
            color: #758296;
        }
    }
    .document:last-child {
        margin: 0;
    }

    .product__tab-cols {
        display: flex;
        justify-content: flex-start;
    }
    .product__tab-col {
        flex: initial;
        min-width: 214px;
        margin: 0 24px 0 0;
    }
    .product__tab-col:last-child {
        margin: 0;
    }
}

.reviews {
   
    .review {
        margin: 0 0 25px 0;
        padding: 0 0 25px 0;
        border-bottom: 1px solid #d9e0ec;

        &:last-child {
            border: 0;
            margin: 0;
        }
        &__title {
            display: flex;
            align-items: center;
            margin: 0 0 10px 0;
        }

        &__name {
            font-weight: 500;
            font-size: 16px;
            color: #0a182f;
            margin: 0 20px 0 0;
        }

        &__info {
            display: flex;
            align-items: center;
        }

        &__rating {
            margin: 0 20px 0 0;
        }

        &__date {
            font-size: 14px;

            color: #758296;
        }

        &__text {
            font-size: 14px;

            line-height: 1.5;

            color: #0a182f;
        }
    }
    .product {
        &__rating {
            display: flex;
            flex-direction: column;
            margin: 0 0 22px 0;
        }

        &__rating-title {
            margin: 0 0 8px 0;
            font-size: 14px;
            color: #758296;
        }
        &__rating-total {
            margin: 0 0 0 15px;
            font-size: 14px;
            color: #0a182f;
        }
    }

    .reviews-tab {
        &__button {
            width: 100%;
        }
    }
}

.discussions {
    &__list {
    }

   

    .discussion {
        margin: 0 0 30px 0;

        &:last-child {
            margin: 0;
        }
        
    }
    .question {
        &__title {
            display: flex;
            align-items: center;
            margin: 0 0 10px 0;
        }

        &__author {
            margin: 0 20px 0 0;
            font-weight: 500;
            font-size: 16px;
            color: #0a182f;
        }

        &__date {
            font-size: 14px;
            line-height: 1.5;
            color: #758296;
        }

        &__text {
            font-size: 14px;
            line-height: 1.5;
            margin: 0 0 14px 0;
            color: #0a182f;
        }
    }
    .answer {
        position: relative;
        padding: 30px 40px 23px 30px;
        background: #ffffff;
        border: 1px solid #d9e0ec;
        box-sizing: border-box;
        border-radius: 3px;

        .answer-arrow {
            position: absolute;
            left: 30px;
            transform: translate(0, -100%);
            top: 1px;
            border: 10px solid transparent;
            border-bottom-color: #d9e0ec;
        }
        .answer-arrow2 {
            position: absolute;
            left: 30px;
            transform: translate(0, -100%);
            top: 2px;
            border: 10px solid transparent;
            border-bottom-color: #fff;
        }

        &__title {
            display: flex;
            align-items: center;
            margin: 0 0 10px 0;
            font-weight: 500;
            font-size: 14px;

            color: #0a182f;
        }

        &__ico {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin: 0 12px 0 0;
            background: #32bea6;
            border: 1px solid #32bea6;
            border-radius: 50px;
            svg {
                width: 10px;
                height: 10px;
                flex-shrink: 0;
                fill: #fff;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 1.5;

            color: #0a182f;
        }
    }

    .pagination {
        margin: 30px 0 0 0;
    }
}

.tab-feedback {
    display: flex;
    justify-content: space-between;

    &__form-container {
        max-width: 780px;

        .title {
            margin: 0 0 10px 0;
            font-family: $secondaryFont;
            font-weight: bold;
            font-size: 24px;
            letter-spacing: 0.03em;
            color: #0a182f;
        }
        .subtitle {
            font-size: 14px;
            color: #758296;
            margin: 0 0 25px 0;
            a {
                color: #758296;
                text-decoration: underline;
            }
        }
        .tab-feedback-rating {
            margin: 0 0 25px 0;
            flex-direction: column;
        }
        .label {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
            color: #0a182f;
            margin: 0 0 12px 0;
        }
        .description {
            font-size: 14px;
            line-height: 1.5;
            color: #758296;
        }

        .field {
            margin: 0 0 20px 0;
        }

        .button {
            min-width: 228px;
        }

        .rating__stars svg {
            cursor: pointer;
        }
    }

    &.hidden {
        display: none;
    }

    &__list {
        max-width: 780px;
        margin: 0 20px 0 0;
    }

    &__form-container {
        display: none;
    }

    &__form-container.active {
        display: block;
    }

    &__action {
        min-width: 228px;
    }

    &__button {
        width: 100%;
    }
}

@include mediaTablet {
    .video-tab {
        .pyoutube-list {
            .youtube-wrapper {
                margin: 0 10px 10px 0;
                // flex: 0 1 47%;
            }
        }
    }

    .tab-feedback {
        flex-wrap: wrap;
        justify-content: flex-start;
        &__list {
            max-width: initial;
            order: 1;
            margin: initial;
        }
        &__form {
        }
        &__action {
            display: flex;
            align-items: flex-start;
            width: 100%;
            min-width: 228px;
            margin: 0 0 35px 0;
        }

        &__button {
            width: 100%;
            max-width: 228px;
            margin: 0 20px 0 0;
        }
    }
    .product-item {
        &__tabs {
            margin: 35px -6px 0 -6px;
        }
        &__tab {
            margin: 0 6px;
            flex: auto;

            &.active {
                .product-item__tab-title {
                    font-size: 16px;
                }
            }
        }
        &__tab-title {
            font-size: 16px;
        }
        &__tab-content {
            top: 70px;
        }
    }

    .reviews {
        .product {
            &__rating {
                order: 1;
                margin: 0;
            }
        }
    }
}
@include mediaPhone {
    .product-item {
        &__tabs {
            flex-direction: column;
            margin: 33px 0 0 0;
        }

        &__tab {
            flex: initial;
            position: initial;
            
            margin: 0;
            margin-bottom: 10px;

            .pagination {
                display: none;
            }
        }

        &__tab:last-child & {
            &__tab-title {
                padding-bottom: 0;
            } 
        }
        &__tab.active {
            margin: 0;
        }
        &__tab.active:last-child {
            margin: 0;
        }

        &__tab-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            font-weight: 500;
            font-size: 14px;
            color: #758296;
            border: 1px solid #D2D7E4;
            border-radius: 5px;

            svg {
                display: block;
                margin: 0 0 0 10px;
                fill: #0a182f;
            }
        }

        &__tab-content {
            position: relative;
            top: initial;
            left: initial;
            display: none;
            // max-height: 0;
            width: 100%;
            margin: 0;
            margin-top: 20px;

            .field {
                flex-wrap: wrap;
                margin: 0 0 10px 0;
            }

            .text-radio label {
                max-width: 140px;
                display: block;
            }
        }

        &__tab.active & {
            &__tab-title {
                font-weight: 700;
                font-size: 14px;
                color: #2D58A4;

                svg {
                    transform: rotate(-90deg);
                    fill: #2D58A4;
                }
            }

            &__tab-content {
                display: block;
            }
        }

        &__tab:hover &{
            &__tab-title {
                color: #2D58A4;

                svg {
                    fill: #2D58A4;
                }
            }
        }
    }

    .discussions {
        .discussion {
            margin: 0 0 15px 0;
        }

        .answer {
            padding: 15px;
        }
    }

    .video-tab {
        .youtube-list {
            .youtube-wrapper {
                flex: 0 1 100%;
                padding: 0;
                margin: 0 0 10px 0;
            }
            .youtube-wrapper:last-child {
                margin: 0 0 10px 0;
            }
        }
    }

    .documents-tab {
        .product__tab-cols {
            flex-wrap: wrap;
        }
        .product__tab-col {
            flex-basis: 100%;
            margin: 0 0 25px 0;

         
        }

        .documents-title {
            margin: 0 0 15px 0;
            h4 {
                margin: 0 0 7px 0;
                font-size: 16px;
            }
            a {
                font-size: 14px;
            }
        }
    }
    .reviews {
        .review {
            margin: 0 0 15px 0;
            padding: 0 0 15px 0;
            &__title {
                flex-direction: column;
                align-items: initial;
            }
            &__name {
                font-size: 14px;
                margin: 0 0 5px 0;
            }

            &__info {
                margin: 0 0 5px 0;
            }

            &__rating {
                margin: 0 10px 0 0;
            }
            &__date {
                font-size: 12px;
            }
        }
        .product {
            &__rating {
                order: 0;
                margin: 0 0 10px 0;
            }
        }
    }
    .product {
        &__tab-cols {
            flex-wrap: wrap;
        }
        &__tab-col {
            flex-basis: 100%;
            margin: 0 0 10px 0;

            // &:first-child {
                border-bottom: 1px solid #d9e0ec;
            // }
        }
        &__tab-col:last-child {
            margin: 0;
        }
    }

    .tab-feedback {
        &__action {
            margin: 0 0 25px 0;
            flex-wrap: wrap;
        }

        &__button {
            width: 100%;
            max-width: initial;
            margin: 0;
        }
    }
}
