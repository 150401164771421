@import 
"variables",
"mixin",
"fonts",
"general",
"buttons",
"dropdown",
"sortBy_dropdown",
"forms",
"youtube_by_click",
"splide",
"splide_cards",
"splide_contacts",
"splide_compare",
"splide_promo",
"splide_catalog_product_item",
"popup_submenu",
"popup_tooltip",
"accordion",
"accordion_filter",
"modal",
"alphabet",
"breadcrumbs",
"mobile_menu",
"socials",
"contacts",
"pagination",
"rating",
"receipt_place",
"filter",
"cards",
"info_page",
"tabs_catalog_by_category",
"tabs_catalog_product_item",
"calc",
"about",
"vendor",
"project",
"articles",
"articles_promo",
"articles_news",
"sortby",
"header",
"baner",
"account", 
"cart",
"cart_checkout",
"catalog_search_result",
"catalog_product_item",
"favorites",
"compare",
"reg",
"footer" 
 