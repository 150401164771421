@import "variables", "mixin";

.sortby {
    display: flex;
    align-items: center;

    margin: 44px 0 0 0;

    &__content {
        margin: 0 30px 0 0;
        display: flex;
        align-items: center;
    }

    &__ico {
        width: 14px;
        height: 14px;
        fill: #758296;
        margin: 0 7px 0 0;
    }
    &__text {
        font-size: 14px;
        color: #758296;
    }

    &__list {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
    }

    &__item {
        margin: 0 30px 0 0;
    }

    &__link {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #0a182f;

        &.active,
        &:active {
            color: #D4343C;
        }
    }
}

.catalog-sort {
    .sortby {
        &__content {
            margin: 0 30px 0 66px;
        }
        &__link {
            color: #0a182f;

            svg {
                width: 10px;
                height: 10px;
                margin: 0 0 0 10px;
                flex-shrink: 0;
            }
            &.active,
            &:active {
                color: #2D58A4;

                svg {
                    fill: #2D58A4;
                }
            }
            &:hover {
                color: #2D58A4;
                svg {
                    fill: #2D58A4;
                }
            }
        }

        &__reset-link,
        &__back-link {
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
            text-decoration: underline;
            color: #D4343C;
            svg {
                margin: 0 10px 0 0;
            }
            &:hover {
                color: #D4343C;
                svg {
                    fill: #D4343C;
                }
            }
        }

        &__reset-link,
        &__back-link {
            svg {
                fill: #D4343C;
            }
        }
        &__reset-link {
            margin: 0 0 0 auto;
        }
    }
}

.tpls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #758296;

    &__list {
        display: flex;
        align-items: center;
        font-size: 0;

        a {
            display: inline-block;
            margin-left: 15px;

            &.active {
                svg {
                    fill: #0A182F;
                }
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #D2D7E4;
        }
    }
}

@include mediaTablet {
    .sortby {
        margin: 27px 0 0 0;
    }

    .catalog-sort {
        .sortby {
            &__back-link {
                display: none;
            }

            &__content {
                margin: 0 30px 0 0;
            }
        }
    }
}
@include mediaPhone {
    .sortby {
        margin: 22px 0 0 0;

        &__ico {
            margin: 0;
        }
        &__text {
            display: none;
        }

        &__item {
            margin: 0 15px 0 0;
        }

        &__content {
            margin: 0 15px 0 0;
        }
    }

    
}
