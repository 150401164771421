@import "variables", "mixin";

* {
	outline: none;
}

input {
	width: 100%;
	padding: 12px 20px;
	position: relative;
	font-family: Gilroy;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 1.5;

	color: #758296;
	background: #ffffff;
	border: 1px solid #d9e0ec;
	box-sizing: border-box;
	border-radius: 5px;

	@include mediaTablet {
		padding: 10px 15px;
		font-size: 14px;
	}
}

textarea {
	width: 100%;
	padding: 15px 20px;
	color: #758296;
	background: #ffffff;
	border: 1px solid #d9e0ec;
	border-radius: 5px;
}

textarea[name="comment"] {
    resize: none;
    height: 120px;
}

.submit-request-form input.file_upload_items {
    padding: 0;
    margin-bottom: 0;
}
.MultiFile-label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #8F9DA8;
}
.MultiFile-label:not(:last-child){
    margin-right: 23px;
}
.upload_files_wrapper input[type="file"] {
    height: 26px;
    margin-bottom: 15px;
    position: absolute;
    top: -3000px;
}
.MultiFile-title {
    margin-right: 7px;
}
.MultiFile-list {
    display: flex;
    flex-wrap: wrap;
}
.MultiFile-remove {
    display: block;
    width: 9px;
    height: 9px;
    position: relative;
}
.MultiFile-remove:before,
.MultiFile-remove:after {
    content: '';
    width: 10px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #000000;
}
.MultiFile-remove:before {
    transform: translate(-50%, -50%) rotate(-45deg);
}
.MultiFile-remove:after {
    transform: translate(-50%, -50%) rotate(45deg);
}
.control-group.tal {
    text-align: left;
}
.upload_files_wrapper {
    margin-bottom: 23px;
}
.upload_files_button {
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    color: #861519;
    text-transform: uppercase;
}
.upload_files_button:hover {
    text-decoration: none;
}
.upload_files_button:before {
    content: '';
    display: inline-block;
    width: 44px;
    height: 44px;
    border: 1px solid #D2D7E4;
    border-radius: 50%;
    background-image: url('../img/ico-clip.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 13px;
}
.upload_files_title, .upload_files_subtitle {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #2D58A4;
}
.upload_files_subtitle {
    color: #758296;
}

textarea {
   resize: none;
}

textarea:hover,
input:hover {
	border: 1px solid #0a182f;
}

textarea:focus,
input:focus {
	color: #0a182f;
}

input.invalid,
textarea.invalid {
	border: 1px solid #D4343C;
}

.input-error-text {
	display: none;
	margin: 8px 0 0 0;
	color: #D4343C;
}

textarea.invalid ~ .input-error-text,
input.invalid ~ .input-error-text,
.captcha.invalid .input-error-text {
	display: block;
}

input.valid {
	color: #0a182f;
}

input.valid ~ svg {
	display: block;
}

.field {
	position: relative;

	svg {
		width: 10px;
		height: 10px;
		display: none;
		position: absolute;
		right: 20px;
		top: 18px;
		// top: 50%;
		// transform: translateY(-50%);
		fill: #0a182f;
	}
	.password-eye {
		width: 16px;
		height: 16px;
		position: absolute;
		display: block;
		background: url(/assets/dev/img/eye-crossed.svg);
		right: 20px;
		top: 18px;
		// top: 50%;
		// transform: translateY(-50%);
		cursor: pointer;
	}

	.password-eye.active {
		background: url(/assets/dev/img/eye.svg);
	}
	
	.description {
	    font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #758296;
	}
}

.item__checkbox {
	.checkbox {
		&__main {
			width: 15px;
			height: 15px;
		}
	}
}

.checkbox {
	position: relative;
	margin: 0 0 5px 0;
	&:last-child {
		margin: 0;
	}
	&_confidential {
	    .checkbox__label {
	        font-size: 12px;
	    }
	}
	&__label {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px;
		line-height: 1.5;
		color: #0a182f;
		cursor: pointer;
		a {
		    margin-left: 5px;
		}
	}
	&__label:hover & {
		&__main {
			border: 1px solid #2D58A4;
		}
	}

	&__input {
		position: absolute;
    left: 9px;
    width: 2px;
    height: 2px;
    opacity: 0;
	}

	&__main {
		display: inline-block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		margin: 0 10px 0 0;
		border: 1px solid #d9e0ec;
		box-sizing: border-box;
		border-radius: 3px;
	}

	&__input:checked + & {
		&__main {
			background: #2D58A4;
			background-image: url("/assets/templates/img/ico-check.svg");
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

.radio {
	margin: 0 0 5px 0;

	&:last-child {
		margin: 0;
	}
	&__label {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 14px;
		line-height: 1.5;
		color: #0a182f;
		cursor: pointer;
	}

	&__label:hover & {
		&__main {
			border: 1px solid #2D58A4;
		}
	}

	&__input {
		position: absolute;
		left: 9px;
		width: 2px;
		opacity: 0;
	}

	&__main {
		display: inline-block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		margin: 0 10px 0 0;
		border: 1px solid #d9e0ec;
		box-sizing: border-box;
		border-radius: 50px;
	}

	&__input:checked + & {
		&__main {
			// border: 1px solid #2D58A4;
			background-image: url("/assets/templates/img/ico-radio-check.svg");
			background-position: 5px 5px;
			background-repeat: no-repeat;
			background-size: 47%;
		}
	}
}

.inline-inputs {
	display: flex;
	justify-content: flex-start;
	align-items: center;
    margin-bottom: 30px;
	.input {
		margin: 0 10px 0 0;
	}
	.input:last-child {
		margin: 0;
	}
}

.text-radio {
	&__label {
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 11px 28px;
			font-family: $secondaryFont;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;

			color: #0a182f;
			border: 1px solid #d9e0ec;
			border-radius: 50px;
			cursor: pointer;
		}
	}
	&__label:hover,
	&.active &__label {
		span {
			border: 1px solid #2D58A4;
		}
	}

	&__input {
		display: none;
	}
	&__input:checked ~ span {
		border: 1px solid #2D58A4;
	}

	.number {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 16px;
		height: 16px;
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		text-align: center;

		border-radius: 50px;

		color: #ffffff;
		background: #2D58A4;
	}

	@include mediaPhone {
		&.input:last-child {
			margin: 10px 0 0 0;
		}

		&__label {
			span {
				padding: 8px 16px;
				font-size: 14px;
			}
		}
	}
}

.product {
	&__buy {
		width: 100%;
		margin: 0 0 10px;
	}

	&__buy-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 10px;
	}
	&__buy-info-button {
		background: none;
		border: none;
	}
	&__buy-info-ico {
		width: 10px;
		height: 10px;
	}

	&__buy-info-price {
		display: flex;
		flex-direction: column;
		white-space: nowrap;
	}
	&__buy-info-price-old {
		font-family: $secondaryFont;
		font-weight: 400;
		font-size: 12px;
		text-decoration: line-through;
		-webkit-text-decoration-color: #758296;
		text-decoration-color: #758296;
		color: #758296;
	}
	&__buy-info-price-total,
	&__buy-info-price-actual {
		font-family: $secondaryFont;
		font-weight: 500;
		font-size: 22px;
		color: #0a182f;

		&.favorites {
			font-size: 24px;
		}
	}

	&__buy-info-price-per-unit {
		font-family: $secondaryFont;
		font-weight: 800;
		font-size: 12px;
		color: #758296;
	}

	&__buy-info-count {
		min-width: 89px;
		display: flex;
		justify-content: space-around;
		background: #ffffff;
		border: 1px solid #d9e0ec;
		box-sizing: border-box;
		border-radius: 3px;
		padding: 13.5px 5px;

		@include mediaTablet {
			padding: 10px 5px;
		}

		&:hover {
			border-color: #2D58A4;
		}
	}

	&__buy-info-button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		border: none;
		padding: 0 5px;
		margin: 0;
		cursor: pointer;
	}
	&__buy-info-count-input {
		width: 30px;
		padding: 0px;
		text-align: center;
		font-size: 14px;
		color: #000000;
		border: 0;
		&:hover {
			border: 0;
		}
	}

	&__buy-button {
		width: 100%;
	}
}

.estimates {
	.product {
		&__buy-info-count {
			min-height: 53px;
		}
	}
}

.jobs-estimate {
	.product {
		&__buy-info-count {
			min-height: initial;
		}
	}
}

.search-container {
	width: 100%;
}

.brand.search-container {
	padding: 0 20px;

	@include mediaTablet {
		padding: 0 50px;
	}
}

.search {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;

	&__input {
		width: 100%;
		padding: 12px 55px 12px 25px;
		background: #fff;
		border: 1px solid #D2D7E4;
		border-radius: 5px;
		-webkit-appearance: none;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
		color: #0A182F;
	}
	&__input:hover {
		background: #ffffff;
		border: 1px solid #d9e0ec;
	}
	&__input::placeholder {
		color: #758296;
	}
	&__input:hover ~ &__button {
		svg {
			fill: #2D58A4;
		}
	}
	&__input:active,
	&__input:focus {
		color: #0A182F;
		background: #FFFFFF;
		border-color: #D2D7E4;

		svg {
			fill: #2D58A4;
		}
	}

	&__button {
		position: absolute;
		padding: 15px 19px;
		background: #fff;
		border: 0;
		right: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		
		@include mediaPhone {
			&:hover svg {
				fill: #D4343C;
			}
		}
	}

	&__ico {
		fill: #0A182F;
	}

	&--header {
		height: 100%;
		border-right: 1px solid #d9e0ec;

		@include mediaTablet {
			border-right: 0;
			border-left: 1px solid #EEF7FF;
		}
		
		@include mediaPhone {
		    border-left: none;
		    width: 100%;
		}

		.search {
			&__input {
				height: 100%;
				border: 0;
			}
		}
	}

	@include mediaTablet {
		&__input {
			width: 100%;
			padding: 10px 35px 10px 16px;
			font-size: 14px;
		}

		&__button {
			padding: 9px 14px 8px 14px;
			right: 1px;
		}
	}

	@include mediaPhone {
		&__input {
			padding: 4px;
			padding-right: 0;
		}

		&__button {
			padding: 8px 14px 7px 14px;
			background: none;
			border: none;
			margin: 0;
		}
		&__ico {
			width: 16px;
			height: 16px;
		}
	}
}

.open_search {
    display: none;
    @include mediaPhone {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
    }
}

.close_search {
    display: none;
    @include mediaPhone {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        position: relative;
        border-left: 1px solid #EEF7FF;
        border-right: 1px solid #EEF7FF;
        flex-shrink: 0;
    }
    &:before, &:after {
        content: '';
        width: 14px;
        height: 1px;
        background-color: #0A182F;
        position: absolute;
        top: 50%;
        left: 50%;
    }
    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.favorites-search-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.search {
		max-width: 466px;
		width: 100%;
		margin: 0;
	}

	.estimate-button {
		svg {
			margin: 0 10px 0 0;
		}
	}
}

.form-group {
    margin-bottom: 20px;
}

.ec-error {
    color: #D4343C;
}

.main_feedback_form {
    button {
        margin: 0!important;
        @include mediaTablet {
            width: 100%!important;
        }
    }
    .checkbox {
        width: 60%;
        @include media(730px) {
            width: 100%;
        }
    }
}

.main_feedback_form .two-one-in-row {
    grid-template-rows: repeat(2, 50px);
    margin-bottom: 33px;
    @include mediaTablet {
        display: flex;
        flex-direction: column;
    }
    .form-group, .upload_files_wrapper {
        margin-bottom: 0;
    }
    .form-group.two-rows {
        grid-area: 1 / 2 / 3 /3;
    }
    textarea {
        height: 120px;
        @include mediaTablet {
            font-size: 14px;
        }
    }
    .upload_files_title {
        text-transform: none;
        font-size: 16px;
        font-weight: normal;
        text-decoration: underline;
        text-underline-offset: 3px;
        @include mediaTablet {
            font-size: 14px;
        }
    }
    .upload_files_button {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        color: #2D58A4;
        width: 100%;
        height: 50px;
        padding: 12px 20px;
        border: 1px solid #2d58a4;
        border-radius: 4px;
        background-color: #F1F6FF;
        &:before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 16px;
            background-image: url(../img/ico-copy.svg);
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 13px;
            border: none;
            border-radius: unset;
        }
    }
    div.MultiFile-label {
        margin-top: 6px;
    }
    
}

.two-in-row.form-footer {
    flex-wrap: wrap;
    @include mediaTablet {
        flex-wrap: nowrap;
    }
    @include mediaPhone {
        flex-wrap: wrap;
    }
    & > * {
        width: 100%;
        @include mediaTablet {
            width: 49%;
        }
        @include mediaPhone {
            width: 100%;
        }
    }
}

@include mediaTablet {
	.field {
		.password-eye,
		svg {
			top: 14px;
		}
 
	}

	.product {
		&__buy-info-price-total,
		&__buy-info-price-actual {
			&.favorites {
				font-size: 16px;
			}
		}
	}
}

@include mediaPhone {
	.brand.search-container {
		padding: 0;
	}

	.favorites-search-container {
		flex-direction: column;
		align-items: flex-start;
		margin: 30px 0 0 0;
		padding: 0;
		.search {			
			&__input {
				display: block;
				padding: 6px 65px 6px 16px;
			}

			&__button {
				margin: 0;
			}
		}

		.estimate-button {
			margin: 15px 0 0 0;
			font-size: 14px;
		}
	}

	.compare-cards {
		.product {
			&__buy-info-price-total,
			&__buy-info-price-actual {
				font-size: 14px;
			}
			&__buy-info-count {
				max-width: 54px;
				min-width: initial;
				padding: 6px;
			}

			&__buy-info-button {
				line-height: 1;
				margin: 0 0 3px 0;
			}
			&__buy-info-ico {
				width: 6px;
				height: 6px;
			}
			&__buy-info-count-input {
				width: 20px;
				font-size: 10px;
			}

			&__buy-button {
				padding: 6px 0;
				font-size: 12px;
			}
		}
	}
}