.about-section {
    &__row {
        display: flex;
        align-items: flex-start;

        @include media(640px) {
            flex-direction: column;
        }
    }

    &__video {
        margin-right: 50px;
        flex: 0.965;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        @include mediaTablet {
            margin-right: 30px;
        }

        @include media(640px) {
            flex: 1;
            margin-right: 0;
            margin-bottom: 20px;
        }

        .play_button {
            display: block;
            color: #fff;
            text-decoration: none;
            font-size: 0;
            cursor: pointer;
            &.inactive {
                &::before, &::after {
                    display: none;
                }
            }

            &::before {
                content: "";
                display: block;
                background: linear-gradient(43.62deg, rgba(0, 0, 0, 0.4) 17%, rgba(0, 0, 0, 0) 59.18%);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &::after {
                content: "";
                display: block;
                background-image: url(../img/video-play.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 70px;
                height: 70px;
                transition: transform 0.3s;
            }

            &:hover {
                &::after {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        picture {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
        }

        .video-info {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            color: #fff;
            padding: 25px;

            @include mediaTablet {
                padding: 20px;
            }

            .name {
                display: block;
                font-weight: 600;
                font-size: 18px;
                line-height: 1.5;
            }

            .desc {
                display: block;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                color: rgba(255, 255, 255, 0.5);
                margin-top: 5px;
            }
        }
    }

    &__text {
        flex: 1;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaTablet {
            font-size: 16px;
        }

        p {
            margin-top: 15px;

            @include mediaTablet {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }
    }

    &__text-link {
        display: inline-block;
        margin-top: 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #2D58A4;

        @include mediaTablet {
            margin-top: 20px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        margin-left: -20px;
        margin-right: -20px;

        @include mediaTablet {
            margin-top: 40px;
            margin-left: -10px;
            margin-right: -10px;
        }

        .about-utp-block {
            width: calc(25% - 40px);
            margin: 0 20px;

            @include mediaTablet {
                width: calc(25% - 20px);
                margin: 0 10px;
            }

            @include media(920px) {
                width: calc(50% - 20px);
                margin-bottom: 20px;
            }
        }
    }
}

.company_employee .splide.history__slider .splide__arrows {
    top: unset;
    svg {
        width: 44px;
        height: 44px;
    }
}

.about-utp-block {
    position: relative;

    &__val {
        font-weight: 800;
        font-size: 65px;
        line-height: 80px;
        letter-spacing: 0.03em;
        color: #2D58A4;
        margin-bottom: 5px;

        @include mediaTablet {
            font-size: 55px;
            line-height: 67px;
        }

        @include mediaPhone {
            font-size: 40px;
            line-height: 1;
        }
    }

    &__desc {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaTablet {
            font-size: 16px;
        }

        @include mediaPhone {
            font-size: 14px;
        }
    }
}


.lazy_video {
    picture {
        display: none;
        &.active {
            display: block;
        }
    }
    iframe {
        display: none;
        &.active {
            display: block;
        }
    }
}
