@import "variables", "mixin";

.mobile-menu {
    z-index: 90;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0 14px;
    border-right: 1px solid #d9e0ec;
    border-left: 1px solid #d9e0ec;

    ul {
        padding: 0;
        list-style: none;
    }
    li {
        margin: 0;
    }

    @include mediaTablet {
        display: flex;
        border-color: #EEF7FF;
    }
    @include mediaPhone {
        padding: 0 10px;
        min-width: 44px;
    }

    &__container {
        position: absolute;
        top: 43px;
        right: 0;
        // height: 100vh;
        width: 100%;
        min-height: 0;
        height: calc(100vh - 104px);
        max-width: 502px;
        background-color: #ffffff;
        text-align: left;
        padding: 30px;
        box-sizing: border-box;
        transform: translateX(101%);
        border-top: 1px solid #d9e0ec;
        transition: transform 0.3s linear, min-height 0.5s linear;
        overflow-y: auto;
        &.active {
            z-index: 100;
            min-height: 100%;
            transform: translateX(0);
            transition: transform 0.3s linear, min-height 0s linear;
            ul {
                height: initial;
            }
        }

        @include mediaPhone {
            width: 100%;
            top: 44px;
        }

        ul {
            // height: 100vh;
            min-height: 390px;
            @include mediaPhone {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                padding: 20px 15px 15px 15px;
            }

            li:first-child {
                margin-top: 0;
                padding-top: 0;
            }

            li {
                margin-bottom: 12px 0;
                padding: 12px 0;
                border-bottom: 1px solid #d9e0ec;

                @include mediaPhone {
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
            a {
                color: #0a182f;
                font-size: 16px;

                display: flex;
                align-items: center;
                justify-content: flex-start;

                @include mediaPhone {
                    font-size: 14px;
                }

                &:hover {
                    color: #D4343C;
                    .mobile-menu__link-ico {
                        fill: #D4343C;
                    }
                }
            }
        }
    }

    &__link-ico {
        fill: #0a182f;
        flex-shrink: 0;
        margin: 0 12px 0 0;
    }
    &__link-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        font-weight: 500;
        font-size: 10px;
        color: #ffffff;
        background: #2D58A4;
        border-radius: 50px;
        margin: 0 0 0 12px;
    }
}

.menu-ico {
    margin-top: 0;
    opacity: 1;
}

.menu-ico {
    position: relative;
    display: block;
    top: 0;
    right: 0;
    text-align: center;
    width: 16px;
    height: 14px;
    cursor: pointer;
    z-index: 95;
    opacity: 1;
    // transition: margin 0.3s linear;

    @include mediaPhone {
    }

    &.active {
        span {
          
            transition: all 0.3s linear;
            &:nth-child(1) {
                top: 50%;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                
                transform: rotate(+45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
               
            }
            &:nth-child(4) {
                bottom: 50%;
                width: 0%;
                left: 50%;
            }
        }
    }
    span {
        height: 2px;
        background-color: #0a182f;
        width: 16px;

        display: inline-block;
        border-radius: 10px;
        position: absolute;
        opacity: 1;
        transition: all 0.3s linear;
      

        &:first-child {
            top: 0;
            left: 0;
        }

        &:nth-child(2) {
            // width: 100%;
            top: 50%;
            margin-top: -1px;
            left: 0;
        }
        &:nth-child(3) {
            // width: 100%;
            top: 50%;
            margin-top: -1px;
            left: 0;
        }
        &:last-child {
            bottom: 0;
            left: 0;
        }
    }
}

.menu-blocker {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    height: 100vh;

    // background-color: transparent;
    background: rgba(0, 0, 0, 0.35);
    cursor: pointer;
    top: 44px;

    opacity: 0;
    visibility: hidden;

    z-index: 10;
    transition: all 0.3s linear;

    &.active {
        opacity: 1;
        visibility: visible;

        // Раскомментировать, если понадобится закрывать меню по клику не только в серой области
        //&::before {
        //     content: "";
        //     display: block;
        //     background: transparent;
        //     width: 100%;
        //     height: 131px;
        //     position: fixed;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        // }
        transition: all 0.1s linear;
        @include mediaTablet {
        }
    }

    @include mediaPhone {
        top: 44px;
    }
}
