@import "variables", "mixin";

.cards {
	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		&::before {
			content: '';
			width: calc(25% - 5px);
			order: 1;
		}

		&::after {
			content: '';
			width: calc(25% - 5px);
		}
	}
}

.card {
	&__item {
		top: 0;
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 20px 20px 15px 20px;
		align-items: center;
		min-width: 228px;
		background: #ffffff;
		border: 1px solid #d9e0ec;
		border-radius: 5px;
		transition: top 0.1s linear;

		.card-delete-button {
			position: absolute;
			// display: none;
			left: 20px;
    		top: 20px;
			padding: 4px;
			svg {
				flex: 0;
				fill: #D4343C;
			}
		}

		&:hover {
			top: -10px;
			transition: top 0.1s linear;
			.card-delete-button {
				display: block;
			}
		}
		@include mediaPhone {
			&:hover {
				top: 0;
			}
		}
	}

	&__data {
		margin-top: auto;
		width: 100%;
	}

	&__status {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0 0 10px 0;
		padding: 10px 0 0 0;
		border-top: 1px solid #d9e0ec;
	}

	&__badge {
		position: absolute;
		left: 20px;
		top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		span {
			display: inline-block;
			width: auto;
			height: 23px;
			line-height: 23px;
			padding: 0 8px;
			font-family: $secondaryFont;
			font-style: normal;
			font-weight: 800;
			font-size: 12px;
			border-radius: 3px;
			margin-bottom: 5px;
		}

		.sale {
			color: #ffa031;
			background: #ffe3c1;
		}

		.new {
			background: #D1FFC1;
			color: #53A42D;
		}

		.hit {
			background: #FFC1C1;
			color: #D4343C;
		}
	}

	&__image {
		margin: 0 0 15px;
	}

	&__title {
		margin: 0 0 15px;
		font-weight: 500;
		font-size: 14px;
		color: #0a182f;
		word-break: break-all;
		line-height: 1.5;
	}

	&__general-info {
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 20px;
		right: 20px;
		margin: 0;
		width: auto;
	}

	&__general-info-code {
		width: 100%;
		font-weight: 400;
		font-size: 12px;
		line-height: 1.5;
		color: #0A182F;
		margin-bottom: 5px;
	}

	&__general-info-icons {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__general-info-ico {
		flex-shrink: 0;
		margin: 0 0 5px 0;
		background: #FFFFFF;
		border: 1px solid #D2D7E4;
		width: 30px;
		height: 30px;
		padding: 0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__general-info-ico svg {
		width: 14px;
		height: 14px;

		fill: #2D58A4;
		cursor: pointer;
		&:hover {
			fill: #D4343C;
		}
	}

	&__buy-button {
		width: 100%;
		min-height: 50px;
		flex: 1;

		svg {
			width: 18px;
			height: 18px;
			fill: #fff;
		}
	}

	&__footer {
		display: flex;
		align-items: center;

		.product__buy-info-count {
			margin-right: 10px;
			min-height: 50px;
			flex: 1;
		}
	}

	&__unit {
		align-self: flex-start;
		font-size: 12px;
		color: #758296;
		// margin: 10px 0 0 0;
	}

	&__row {
		position: relative;
		width: 100%;
		margin-bottom: 10px;
		background: #FFFFFF;
		border: 1px solid #D2D7E4;
		border-radius: 5px;
		display: flex;
		align-items: stretch;
		padding: 20px;
		justify-content: space-between;

		&-header {
			width: 120px;
			margin-right: 15px;

			a {
				display: block;
				margin: 0 !important;
			}

			img {
				width: 100%;
				margin: 0 !important;
			}
		}

		.card__general-info {
			position: static;
			width: auto;
			flex-direction: column;
			flex: 1;

			.card__title {
				margin-bottom: 10px;
			}

			&-row {
				display: flex;
    			align-items: center;
			}

			&-code {
				width: auto;
				margin-right: 25px;
				margin-bottom: 0;
			}

			&-icons {
				flex-direction: row;

				.card__general-info-ico {
					display: inline-block;
					width: 14px;
					height: 14px;
					padding: 0;
					border: 0;
					border-radius: 0;
					margin: 0;
					margin-right: 12px;
				}
			}

			.product-count {
				margin-bottom: 0;
				margin-right: 15px;
			}

			.product-permanent {
				margin-bottom: 0;
				margin-right: 15px;
			}
		}

		.card__data {
			width: 250px;
			border-left: 1px solid #D2D7E4;
			padding-left: 40px;
			margin-top: -20px !important;
			margin-bottom: -20px !important;
			padding: 20px 0 20px 40px;
			margin-left: 20px !important;
		}

		.product__buy-info-price-total, .product__buy-info-price-actual {
			font-size: 16px;
			font-weight: 500;
		}

		.product__buy-info-count {
			margin-left: 10px;
		}

		.card__buy-button {
			svg {
				margin-right: 10px;
			}
		}
	}
}



.product-permanent {
	font-size: 12px;
	line-height: 1.5;
	display: flex;
	align-items: center;
	margin: 0 0 5px 0;

	color: #53A42D;
	svg {
		width: 5px;
		height: 5px;
		fill: #53A42D;
		margin: 0 6px 0 0;
	}
}

.product-count {
	font-size: 12px;
	line-height: 1.5;
	color: #000000;
	margin: 0 0 5px 0;
}

.product-instock-link {
	font-size: 12px;
	line-height: 1.5;
	color: #D4343C;
}

.catalog-cards.cards {
	flex-grow: 1;
}

.catalog-cards .card {
	&__item {
		flex: 0 1 calc(25% - 5px);
		margin: 0 0 10px 0;
		min-width: initial;
		max-width: 228px;
	}
 
	&__data {
		margin-top: auto;
	}
}

.compare-cards {
	.card {
		&__item {
			.count {
				display: none;
				position: absolute;
				right: 19px;
				top: 40px;
				font-size: 10px;
				line-height: 1.5;
				color: #758296;
			}
		}
 
		&__data {
			margin-top: auto;
		}
	}
}

@include mediaTablet {
	.catalog-cards .card {
		&__item {
			flex: 0 1 calc(33% - 5px);
		 
			max-width: initial;
		}

		&__item:last-child {
			margin: 0 auto 10px 10px;
		}
	}
}

@include media(760px) {
	.catalog-cards .card {
		&__item {
			flex: 0 1 calc(50% - 5px);

			max-width: initial;
		}
	}

	.card {
		&__row {
			flex-direction: column;

			&-header {
				margin-right: 0;
				margin-bottom: 15px;
				width: 200px;
			}

			.card__general-info {
				width: 100%;

				&-row {
					margin-top: 5px;
				}
			}

			.card__data {
				width: 100%;
				margin: 20px 0 0 0 !important;
				padding: 0;
				border-left: 0;
			}
			
			.product__buy {
				form {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			.product__buy-info {
				margin: 0;
				margin-right: 15px;
			}

			.card__footer {
				flex: 1;
			}

			.card__buy-button {
				min-height: 44px;
			}
		}
	}
}

@include mediaPhone {
	.catalog-cards .card {
		&__item {
			flex: 0 1 100%;
			max-width: initial;
		}

		&__item:last-child {
			margin: 0;
		}
	}

	.compare-cards {
		.card {
			&__item {
				padding: 12px 12px 10px 12px;
			 
				min-width: initial;
				.card-delete-button {
					right: 12px;
					top: 12px;
					left: auto;
				}

				.count {
					display: block;
				}
			}

			&__status {
				margin: 0 0 5px 0;
				padding: 5px 0 0 0;
			}

			&__image {
				max-width: 46px;
				margin: 0 auto 5px 0;
			}

			&__title {
				margin: 0 0 5px;
				font-size: 10px;
			}

			&__general-info {
				margin: 0 0 10px;
			}

			&__general-info-code {
				font-size: 12px;

				color: #000000;
			}

			&__general-info-icons {
				display: none;
			}

			&__buy-button {
				padding: 6px 0;
				font-size: 12px;
			}

			&__unit {
				font-size: 10px;
			}
		}

		.product-permanent {
			font-size: 10px;
		}

		.product-count {
			font-size: 10px;
		}

		.product-instock-link {
			font-size: 10px;
		}
	}

	.card {
		&__row {

			.card__general-info-row {
				flex-wrap: wrap;
			}

			.product__buy {
				form {
					display: block;
				}
			}

			.product__buy-info {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}
