@import "variables", "mixin";

@include mediaTablet {
    .home-page .news .article:nth-last-child(-n + 3) {
        display: none;
    }
}

@include mediaPhone {
    .news .article {
        padding: 20px;

        &__image {
            align-self: flex-start;
        }

        &__text {
            padding: 17px 0 0 0;
        }
        
        &__text-title {
            -webkit-line-clamp: 3;
        }

        &__text-description {
            -webkit-line-clamp: 2;
        }
    }
 
}
