@import 
"variables",
"mixin";
.search-result {
    margin: 28px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__filter {
    }

    &__cards {
        flex-basis: 100%;
    }

    .sidebar {
        flex-basis: 100%;
        margin: 0 10px 0 0;
        padding: 20px;
        max-width: 228px;
        min-width: 228px;
        background: #ffffff;
        border: 1px solid #d9e0ec;
        border-radius: 5px;
    }
}

@include mediaTablet {
    .search-result {
        margin: 28px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__filter {
        }

        &__cards {
        }

        .sidebar {
            display: none;
        }
    }
}
