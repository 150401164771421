@import "variables", "mixin";
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
    opacity: 0;
    visibility: hidden;
    padding: 32px;
    display: flex;
    
    z-index: 100;
    cursor: pointer;
    transition: opacity 0.2s linear;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
   

    &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s linear;
    }

    &__container {
        position: relative;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        width: 100%;
        cursor: default;
    }
    &__close-button {
        position: absolute;
        right: 30px;
        top: 30px;
        background: transparent;
        border: 0;
        cursor: pointer;
        svg {
            pointer-events: none;
            width: 18px;
            height: 18px;
        }
    }

    &__content {
        position: relative;
        padding: 50px 55px 50px 55px;
        background: #fff;
        width: 100%;
    }

    &__title {
        margin: 0 0 45px 0;
        .title {
            font-family: $secondaryFont;
            font-weight: 700;
            font-size: 32px;
            color: #0a182f;
            @include mediaPhone {
                font-size: 22px;
            }
        }
    }
    
    &__subtitle {
        font-size: 14px;
        &.gray {
            color: #758296;
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }

    &__content-main {
    }
}

.modal-tabs {
    display: flex;
    flex-wrap: wrap;

    &__content {
        flex-basis: 100%;

        p {
            font-size: 14px;
            line-height: 1.5;
            text-align: center;
            color: #0a182f;
        }
    }

    .tab {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 120px;
        margin: 0 0 0 20px;
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #0a182f;
        background: #FFFFFF;
        border: 1px solid #D2D7E4;
        border-radius: 5px;
        img {
            margin: 0 15px 0 0;
        }
        &:first-child {
            margin: 0;
        }

        &.active,
        &:active,
        &:hover {
            color: #2D58A4;
            border-color: #2D58A4;
        }
    }

    .private,
    .organization {
        display: none;
    }
    .organization-tab.active ~ .organization,
    .private-tab.active ~ .private {
        display: block;
    }
}

.filter-modal {
    .modal__container {
        max-width: 704px;
    }

    .filter__header {
        margin-top: -30px;
        margin-bottom: 15px;
    }

    .modal__close-button {
        right: 20px;
        top: 23px;
    }
}

.in-stock-modal {
    .modal__container {
        max-width: 1180px;
    }
}

.order-checkout-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 0;
        img {
            margin: 0 0 26px 0;
        }
    }
    .modal__content {
        p {
            margin: 20px 0 0 0;
            font-size: 16px;
            text-align: center;

            b {
                font-weight: 500;
            }
        }
    }
}

.receipt-place-modal {
    .alphabet {
        margin: 30px 0 0;

        &__details {
            padding: 0;
            border: 0;
        }
    }

    .modal__container {
        max-width: 1180px;
    }

    .search-container {
        width: 100%;
    }

    .search {
        max-width: initial;
        margin: 0;
    }
}

.password-changed-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 0;
        img {
            margin: 0 0 26px 0;
        }
    }
}
.reg-modal {
    .modal__container {
        max-width: 900px;
    }
    .modal__title {
        text-align: center;
    }
}
.sendex-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title, .sendex-modal_message {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}
.login-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title {
        text-align: center;
    }
    .modal-tabs {
        &__content {
        }
        .tab {
            min-height: 80px;
            // max-width: 220px;
            font-size: 14px;
            img {
                margin: 0 10px 0 0;
                width: 34px;
                height: 34px;
            }
        }
    }
    .field {
        position: relative;
    }
    .modal-login-form {
        margin: 30px 0 0 0;
        form {
            margin: 0 0 30px;
        }

        .fields {
            .field {
                margin: 0 0 20px 0;
            }
        }
        .fields:last-child {
            .field:last-child {
                margin: 0;
                padding: 0 0 30px 0;
                border-bottom: 1px solid #d9e0ec;
            }
        }
    }
    .modal-restore-form {
        margin: 20px 0 0 0;
        form {
            margin: 20px 0 30px;
        }

        .fields {
            .field {
                margin: 0 0 20px 0;
                max-width: 460px;
            }
        }
        .fields:last-child {
            .field:last-child {
                margin: 0;
                padding: 0 0 30px 0;
                border-bottom: 1px solid #d9e0ec;
            }
        }
        .step2 {
            display: none;
            align-items: center;

            &.active {
                display: flex;
            }

            .field {
                flex: 1;
                margin: 0 12px 20px 0;

                &:last-child {
                    margin: 0 0 20px 0;
                }
            }
        }
        .step3 {
            display: none;
            &.active {
                display: block;
            }
        }
    }

    .modal-login-button {
        width: 100%;
        padding: 15px 0;
    }

    .modal-reg-button {
        padding: 15px 0;
        background: #ffffff;
        color: #2D58A4;
        border: 1px solid #2D58A4;

        &:hover {
            color: #ffffff;
            background: #2D58A4;
        }
    }
    .restore-password-button {
        width: 100%;
    }
    .modal-forgot-password-button {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: #2D58A4;

        &:hover {
            color: #D4343C;
        }
    }

    .restore-password-button {
        width: 100%;
        padding: 15px 0;
    }

    .counter-input {
        background: #dce9ff;
        border: 2px solid #2D58A4;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #2D58A4;
        text-align: center;
    }
}
.email-sent-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__content {
        text-align: center;
    }
}
.feedback-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title {
        text-align: center;
        margin-bottom: 30px;
        .title {
            margin-bottom: 15px;
        }
    }
    .modal__subtitle {
        width: 80%;
        margin: 0 auto;
        @include mediaPhone {
            width: 100%;
        }
    }
}
.review-modal {
    .modal__container {
        max-width: 900px;
    }
    .modal__title {
        margin-bottom: 20px;
        .title {
            margin-bottom: 15px;
        }
    }
}
.modal-label {
    color: #0A182F;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

@include mediaTablet {
    .modal {
        &__close-button {
            right: 20px;
            top: 20px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &__content {
            padding: 40px 30px 40px 30px;
        }

        &__title {
            margin: 0 0 30px 0;
            font-size: 30px;
        }

        &__content-main {
        }
    }
    .receipt-place-modal {
        .alphabet {
            margin: 20px 0 0;
        }
    }

    .modal-tabs {
        .tab {
            min-height: 90px;
            margin: 0 0 0 10px;
            // max-width: initial;
            font-size: 22px;
            img {
                width: 44px;
                height: 44px;
            }
        }
    }

    .login-modal {
        .modal__container {
            max-width: 704px;
        }
    }
    .password-changed-modal {
        .modal__container {
            max-width: 490px;
        }
    }
}

@include mediaPhone {
    .modal {
        padding: 15px;
        &__container {
            // margin: 15px;
        }
        &__close-button {
            right: 10px;
            top: 10px;

            svg {
                width: 16px;
                height: 16px;
            }
        }

        &__content {
            padding: 23px 20px 20px 20px;
        }

        &__title {
            margin: 0 0 25px 0;
            font-size: 26px;
        }

        &__content-main {
        }
    }

    .receipt-place-modal {
        .receipt-place-modal {
            .alphabet {
                margin: 23px 0 0;
            }
        }
        .search {
            &__input {
                display: block;
            }
        }
    }

    .filter-modal {
        .modal__close-button {
            right: 10px;
            top: 7px;
        }
    }

    .modal-tabs {
        flex-direction: column;
        .tab {
            min-height: 76px;
            margin: 10px 0 0 0;
            font-size: 18px;
            max-width: initial;
            img {
                width: 34px;
                height: 34px;
            }
        }
    }

    .login-modal {
        .modal-tabs {
            .tab {
                max-width: initial;
            }
        }
    }

    .password-changed-modal {
        .modal__container {
            max-width: 290px;
        }
    }
}

.message-modal {
    .modal__container {
        max-width: 580px;
    }
    .modal__title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin: 0;

        .preloader {
            margin-bottom: 15px;
            width: 88px;
            height: 88px;
            background-image: url(../img/preloader.gif);
            background-position: center;
        }

        .title {
            margin-bottom: 20px;
        }
    }
}

.modal__share {
    &-block {
        margin-top: 25px;
        width: 100%;
        text-align: left;

        p {
            margin-bottom: 15px;
        }
    }

    &-output {
        display: flex;
    }

    &-link {
        margin-right: 10px;
        padding: 12px 21px;
        flex-grow: 1;
        border: 1px solid #d9e0ec;
        font-size: 16px;
        line-height: 1.5;
        color: #758296;
    }

    &-button {
        width: 130px;
        height: 50px;
        background: #ffffff;
        color: #2D58A4;
        border: 1px solid #2D58A4;

        &:hover {
            color: #ffffff;
            background: #2D58A4;
            border: none;
        }
    }

    &-input {
        margin-bottom: 20px;
    }

    &-error {
        display: none;
        font-size: 12px;
        color: #D4343C;

        &.active {
            display: block;
        }
    }
}