@import "variables", "mixin";

.cart {
   

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 44px 0 0;
    }

    &__items {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 40px 0 0;
    }

    &__columns-titles {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin: 0 0 25px 0;
        .title {
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 18px;

            color: #0a182f;
        }
        .description-title {
        }
        .count-title {
            margin: 0 100px 0 auto;
        }
        .total-title {
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
    }

    &__action {
        padding: 30px 40px 40px 40px;
        background: #EEF7FF;
        border-radius: 5px;
        width: 100%;
        max-width: 380px;
    }
    &__action-payment {
        margin: 0 0 30px 0;
        .cart__action-title {
            margin: 0 0 7px;
            text-transform: none;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
        }
    }
    &__action-cols {
    }

    &__action-col {
    }
    .cart-action-top {
        margin: 0 0 15px 0;
    }
    .cart-action-top.checkout {
        margin: 0 0 30px 0;
    }
    .cart-action-bottom {
    }
    &__action-title {
        margin: 0 0 20px;
        font-family: $secondaryFont;
        font-weight: 700;
        font-size: 24px;

        color: #0a182f;
    }
    &__action-title.checkout {
        margin: 0 0 20px 0;
    }
    &__action-details {
        display: flex;
        flex-direction: column;

        p {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 10px;
            font-size: 14px;
            color: #758296;

            &:last-child {
                margin: 0;
            }
        }

        span {
            font-weight: 500;
            font-size: 14px;
            color: #000000;
        }
        i {
            border: 1px dashed #d9e0ec;
            height: 1px;
            flex: 1;
        }
    }

    .checkout-details {
        p {
            flex-direction: column;
            align-items: flex-start;

            &:last-child {
                margin: 0;
            }
        }

        span {
            font-weight: 500;
            color: #0a182f;
        }
    }

    &__action-promo {
    }

    .promo {
        margin: 0 0 35px 0;
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__input {
            // max-width: 250px;
            margin: 0 10px 0 0;
            padding: 8px 20px;
            width: 100%;
            font-size: 14px;
            height: 40px;
        }
        &__button {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            svg {
                width: 10px;
                height: 10px;
                margin: 0;
                flex-shrink: 0;
                fill: #fff;
            }
        }
    }
    &__action-total {
        margin: 0 0 20px 0;
        p {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #0a182f;
        }

        span {
        }

        i {
            border: 1px dashed #d9e0ec;
            height: 1px;
            flex: 1;
        }
       
    }

    .checkout-total {
        p {
            font-family: Gilroy;
            text-transform: initial;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #758296;
        }
        span {
            font-weight: 500;
            color: #000000;
        }
    }
    .section {
        &__main {
        }

        &__header {
        }
    }

    .clear-cart-form {
        align-self: flex-end;
    }

    .clear-cart-button {
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 14px;

        text-transform: uppercase;

        margin: 30px 0 0 0;
        svg {
            width: 14px;
            height: 14px;
            margin: 0 0 0 10px;
        }
    }

    .cart-checkout-button {
        width: 100%;
        max-width: initial;
        text-transform: none;
        font-weight: 500;
        font-size: 16px;
        height: 50px;
        line-height: 48px;
    }
}
.save-estimate-button {
    font-size: 14px;
    white-space: nowrap;
    svg {
        margin: 0 10px 0 0;
    }
}
.account {
    .item {
        &__info {
            margin: 0;
        }
    }
}
.item {
    position: relative;
    padding: 25px 0 30px 0;
    
    border-bottom: 1px solid #d9e0ec;

    &:first-child {
    
        border-top: 1px solid #d9e0ec;
    }
   

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__image {
        max-width: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0 15px 0 0;
        flex-shrink: 0;
        img {
            flex-shrink: 0;
            width: 100%;
        }
    }

    &__details {
        position: relative;
        width: 100%;
    }
    &__name {
        display: block;
        width: 52%;
        max-width: 382px;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        color: #0a182f;
    }
    &__name.order-detail {
        width: 80%;
        max-width: initial;
    }
    
    &__info {
        display: flex;
        justify-content: space-between;
        margin: 0 0 10px 0;
    }

    &__info-list {
        display: flex;
    }

    &__info-col {
        font-size: 12px;
        margin: 0 40px 0 0;

        &.inline {
            display: flex;
            p {
                margin: 0 20px 0 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
        p {
            color: #758296;
        }

        span {
            color: #0a182f;
        }

        .count {
            display: none;
        }
    }
    &__info-col:last-child {
        margin: 0;
    }

    &__calc {
        margin: -10px 0 0 0;
    }
    &__count {
        font-size: 14px;
        color: #000000;
        margin: 0 100px 0 auto;
    }

    &__characteristic {
        display: flex;
        justify-content: flex-start;
        padding: 8px 0 8px 84px;
        background: #EEF7FF;
        font-size: 12px;
        p {
            color: #758296;
            margin: 0 12px 0 0;
        }

        span {
            color: #0a182f;
        }
    }

    .product {
        &__buy {
            margin: 0;
        }

        &__buy-info {
            position: relative;
            top: 0;
            margin: 0;
        }

        &__buy-info-count {
            margin: 0 50px 0 0;
        }

        &__buy-info-button {
        }

        &__buy-info-ico {
        }

        &__buy-info-count-input {
        }

        &__buy-info-price {
            margin: 0 43px 0 0;
            align-items: flex-end;

            &-total {
                font-weight: 500;
                font-size: 16px;
            }

            &-per-unit{
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
            }
        }

        &__buy-info-price.order-detail {
            margin: 0;
        }
        &__buy-info-price.checkout {
            margin: 0;
        }

        &__buy-info-price-total {
        }
        &__buy-info-price-per-unit {
        }
        &__delete-button {
            position: absolute;
            right: -10px;
            bottom: 77px;
            svg {
                fill: #758296;
                margin: 0;
            }
        }
        &__delete-button:hover {
            svg {
                fill: #D4343C;
            }
        }
    }
}

// @include media(1100px) {
@include mediaTablet {
    .cart {
        &__top {
        }

        &__content {
            margin: 27px 0 0 0;
            flex-direction: column;
        }

        &__items {
        }

        &__item {
        }

        &__action {
            width: 100%;
            margin: 40px 0 0 0;
            padding: 25px 25px 30px 25px;
            max-width: initial;
        }
        &__action-cols {
            display: flex;
            justify-content: space-between;
        }

        &__action-col {
            width: 100%;
        }
        .cart-action-top {
            margin: 0 60px 0 0;
        }
        .cart-action-top.checkout {
            margin: 0 60px 0 0;
        }
        .cart-action-bottom {
            max-width: 297px;
        }
        &__action-title {
            margin: 0 0 15px 0;
            font-size: 22px;
        }
        &__action-title.checkout {
            margin: 0 0 15px 0;
        }
        &__action-details {
        }

        &__action-promo {
        }

        &__action-total {
            margin: 0 0 20px 0;
        }

        .promo {
            margin: 0 0 20px 0;
            max-width: initial;
            &__container {
            }

            &__input {
            }
        }

        .cart-checkout-button {
        }
    }

    .save-estimate-button {
        svg {
            width: 14px;
            height: 14px;
        }
    }

    .item {
        padding: 15px 0 14px 0;
       
        &__characteristic {
            flex-wrap: wrap;
        }

        .product {
            
            &__delete-button {
                top: 13px;
            }
        }
    }
    .clear-cart-form {
        align-self: flex-end;
    }
}

@include mediaPhone {
    .cart {
        &__top {
            flex-direction: column;
            align-items: flex-start;
        }

        &__content {
            margin: 30px 0 0 0;
        }

        &__items {
        }
        &__columns-titles {
            display: none;
        }
        &__item {
        }

        &__action {
            padding: 20px;
        }
        &__action-cols {
            flex-direction: column;
        }

        &__action-col {
        }
        .cart-action-top {
            max-width: initial;
            margin: 0 0 20px 0;
        }
        .cart-action-top.checkout {
            margin: 0 0 20px 0;
        }
        .cart-action-bottom {
            max-width: initial;
        }
        &__action-title {
            margin: 0 0 10px 0;
            font-size: 18px;
        }
        .cart__action-title.checkout {
            margin: 0 0 10px 0;
        }
        &__action-details {
        }

        &__action-promo {
        }

        &__action-total {
        }

        .clear-cart-button {
            margin: 25px 0 0 0;
        }
    }

    

    .save-estimate-button {
        svg {
            margin: 0 10px 0 0;
        }
    }

    .item {
        padding: 16px 0 16px 0;
        &:first-child {
        }

        &__image {
            margin: 0 auto 7px 0;
        }

        &__content {
            flex-direction: column;
        }
        &__details {
        }

        &__name {
            margin: 0 0 5px 0;
            width: initial;
        }

        &__info {
        }

        &__info-list {
            flex-direction: column;
        }

        &__info-col {
            p {
                display: flex;
            }
            .count {
                display: block;
            }
        }
        &__count {
            display: none;
        }
        &__calc {
        }

        &__characteristic {
            padding: 12px 15px;

            p {
                margin: 0 8px 0 0;
            }
        }

        .product {
            &__buy {
            }

            &__buy-info {
                flex-direction: column;
                position: static;
                margin: 0 0 12px 0;
            }

            &__buy-info-count {
                margin: 0 0 10px 0;
            }

            &__buy-info-button {
            }

            &__buy-info-ico {
            }

            &__buy-info-count-input {
            }

            &__buy-info-price {
                margin: 0;
            }

            &__buy-info-price-total {
            }
            &__buy-info-price-per-unit {
            }

            &__delete-button {
                right: -10px;
                top: 22px;
            }
        }
    }
    .cart {
        .item {
            &__calc {
                margin: 0;
            }
        }
    }
    .account {
        .item {
            &__info {
                margin: 0;
                align-items: center;
            }
            &__info-col.inline {
                flex-direction: column;
            }
            &__calc {
                margin: 0;
            }
        }
    }

    .promo {
        max-width: initial;

        &__container {
        }

        &__input {
        }
    }

    .cart-checkout-button {
    }
}

.js-order-form-wrapper {
    display: none;

    form {
        display: none;
    }
}

#msOrder.cart__action {
    margin-top: 0;
}