@import 
"variables",
"mixin";
.popup-submenu {
    position: absolute;
    right: 0;
    top: 35px;
    padding: 28px 35px 35px 35px;
    min-width: 254px;
    text-align: right;
    background: #ffffff;
    border: 1px solid #d9e0ec;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 100;
    cursor: default;
    &__list {
        padding: 0;
        list-style: none;
    }

    .center-arrow {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -100%);
    }
    .right-arrow {
        position: absolute;
        left: 80%;
        transform: translate(-50%, -100%);
    }
    &__arrow {
        top: 1px;
        border: 10px solid transparent;
        border-bottom-color: #d9e0ec;
    }

    &__arrow2 {
        top: 2px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
    }

    &__item {
        margin: 0 0 24px 0;
    }
    &__item:last-child {
        margin: 0;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;

        color: #0a182f;
    }
    &__link:active,
    &__link:hover {
        color: #D4343C;
    }
    &__link:active,
    &__link:hover {
        .popup-submenu__ico {
            fill: #D4343C;
        }
    }
    &__ico {
        margin: 0 10px 0 0;
    }
}
// .popup-submenu-trigger.active ~ .popup-submenu
.popup-submenu-trigger.active {
    .text-button--primary {
        span {
            color: #D4343C;
        }

        svg {
            fill: #D4343C;
        }
    }
    .popup-submenu {
        visibility: visible;
        opacity: 1;
        transition: all 0.2s linear;
    }
}
