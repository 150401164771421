@import "variables", "mixin";

.sortby-dropdown {
    display: flex;
    align-items: center;
    &__title {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0 30px 0 0;

        color: #758296;

        svg {
            width: 16px;
            height: 16px;
            fill: #758296;
            margin: 0 8px 0 0;
        }
    }
    &__arrow {
        position: absolute;
        top: 1px;
        left: 20px;
        transform: translate(-10%, -100%);
        border: 10px solid transparent;
    }
    &.active &__arrow {
        border-bottom-color: #d9e0ec;
    }

    &__arrow2 {
        position: absolute;
        top: 2px;
        left: 20px;
        transform: translate(-10%, -100%);
        border: 10px solid transparent;
        border-bottom-color: #fff;
    }
    &__input-container {
        position: relative;
        margin: 0 30px 0 0;
        display: none;
        svg {
            position: absolute;
            width: 8px;
            height: 8px;
            right: 3px;
            top: 7px;
         
        }
    }
    &__content {
        display: flex;
        align-items: center;
    }

    &__input {
        padding: 0;
        max-width: 100px;
        font-size: 14px;

        color: #0a182f;
        border: 0;
        &:focus,
        &:hover {
            border: 0;
        }

        &[data-dir="asc"] + svg {
            transform: rotate(180deg);
        }
        &[data-dir="desc"] + svg {
            transform: rotate(0);
        }
    }

    &__list {
        display: flex;
    }

    .sort {
        display: flex;
        align-items: center;
        margin: 0 30px 0 0;
        font-size: 14px;
        color: #0a182f;

        svg {
            width: 8px;
            width: 8px;
            margin: 0 0 0 10px;
            fill: #0a182f;
        }
        &[data-dir="asc"] {
            svg {
                transform: rotate(180deg);
            }
        }
        &[data-dir="desc"] {
            svg {
                transform: rotate(0deg);
            }
        }
    }

    // &__item:hover,
    .sort.active {
        cursor: pointer!important;
        color: #2D58A4!important;

        svg {
            fill: #2D58A4;
        }
    }
}
.compare-sort {
    .sortby-dropdown {
        &__item {
            margin-right: 30px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            color: #0A182F;
        }
        &__item.active {
            color: #D4343C;
        }
        &__input {
            max-width: initial;
        }
    }
}

@include mediaTablet {
    .compare-sort {
        order: 3;
        flex-basis: 100%;
    }
}

@include mediaPhone {
    .compare-sort { 
        margin: 5px 0 0 0;
    }
    .sortby-dropdown {
        cursor: pointer;
        &__title {
            margin: 0;
            span {
                display: none;
            }
        }
        &__input-container {
            display: flex;
        }
        &__input {
            cursor: pointer;
        }
        &__list {
            position: absolute;
            display: none;
            flex-direction: column;
            top: 30px;
            left: -25px;
            padding: 22px 30px;
            background: #ffffff;
            border: 1px solid #d9e0ec;
            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
            border-radius: 3px;

            box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
            border-radius: 3px;
            z-index: 999;
        }
        .sort {
            margin: 0 0 8px;

            &:last-child {
                margin: 0;
            }
        }
        &__content {
            position: relative;
        }

        &.active & {
         
            &__list {
                display: flex;
            }

         

            &__title {
                svg {
                    fill: #2D58A4;
                }
            }
        }
    }
}
