@import "variables", "mixin";

.baner {
    background: #3C3C3C;

    &__title {
        font-size: 46px;
        line-height: 1.3;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 20px;
        max-width: 570px;
        text-transform: uppercase;
        @include mediaTablet {
            font-size: 32px;
            max-width: 365px;
        }
        @include mediaPhone {
            font-size: 28px;
        }
        svg {
            position: relative;
            right: 0;
            width: 10px;
            height: 10px;
            fill: #ffffff;
            margin: 0 0 0 10px;
            transition: 0.1s linear;
        }
    }
    &__link {
        &:hover {
            svg {
                right: -5px;
                fill: #ffffff;
                transition: 0.1s linear;
            }
        }
    }
    &__container {
        width: 100%;
        max-width: 100%;
        @include media(1200px) {
            padding: 0;
        }
    }
    &__slide {
        height: 560px;
        background-position: center;
        background-size: cover;
        @include mediaTablet {
            height: 380px;
        }
    }
    &__slider {
    }

    .splide__pagination {
        display: flex;
    }

    .splide__slide img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .splide__arrow svg {
        width: 44px;
        height: 44px;
    }
    &__wrapper {
        height: 100%;
        width: 100%;
        max-width: 1180px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @include media(1200px) {
            padding: 0 15px;
        }
        @include mediaTablet {
            padding: 0 32px;
        }
        @include mediaPhone {
            padding: 40px 15px;
            justify-content: flex-start;
        }
    }
    &__button {
        font-family: inherit;
        text-transform: initial;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        display: inline-block;
        padding: 14px 29px;
        @include mediaTablet {
            padding: 12px 29px;
        }
        svg {
            position: relative;
            right: 0;
            width: 16px;
            height: 9px;
            fill: #ffffff;
            margin: 0 0 0 13px;
            transition: 0.1s linear;
            transform: scale(-1, 1);
        }
    }
}

a.baner__button {
    color: #ffffff;
}

@include mediaTablet {
    .baner {
        .splide__arrows {
            display: none;
        }
    }
}
@include mediaPhone {
    .baner {
        .splide__arrows {
            display: none;
        }

        .splide__pagination {
            bottom: 10px;
        }
    }
}
