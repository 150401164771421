@import "variables", "mixin";

.receipt-place-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0 0;
}

.receipt-place {
    margin: 0 0 10px 0;

    &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 5px 0;
        font-weight: 600;
        font-size: 18px;

        color: #0a182f;
        svg {
            width: 18px;
            height: 18px;
            fill: #0a182f;
            margin: 0 12px 0 0;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #0a182f;
        margin: 0 0 0 31px;
    }

    &__button {
        font-size: 14px;
        margin: 0 0 0 25px;
        text-decoration: underline;
        color: #2D58A4;

        &:hover {
            text-decoration: none;
            color: #2D58A4;
        }
    }
    &.topbar {
        margin: 0;
    }
    &.topbar & {
        &__button {
            margin: 0;
        }
    }
}
.city-select {
    .receipt-place {
        &__button {
            margin: 0;
        }
    }
}
@include mediaTablet {
    .receipt-place {
        // margin: 0 0 10px 0;
        &__title {
            font-size: 14px;
        }

        &__name {
            font-size: 14px;
        }
    }
}
@include mediaPhone {
    .receipt-place-container {
        margin: 25px 0 0 0;
    }
    .receipt-place {
        // margin: 0 0 10px 0;
        &__title {
            svg {
            }
        }

        &__name {
            flex-direction: column;
            align-items: flex-start;
            // margin: 0 0 0 30px;
        }

        &__button {
            margin: 5px 0 0 0;
        }
    }
}
