.calc-page {
    padding: 40px 0 60px 0;

    @include mediaTablet {
        padding: 35px 0 45px 0;
    }

    @include mediaPhone {
        padding: 30px 0 40px 0;
    }

    .calc {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
            margin-top: 50px;

            @include mediaPhone {
                margin: 35px 0 0 0;
            }

            .block-calc {
                width: calc(33.333% - 20px);
                margin: 0 10px 20px 10px;

                @include mediaTablet {
                    width: calc(50% - 20px);
                }

                @include mediaPhone {
                    width: 100%;
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}

.calc {
    &__list {
        display: flex;
        align-items: flex-start;
        margin-top: 50px;

        @include mediaTablet {
            margin-top: 35px;
        }

        @include mediaPhone {
            margin-top: 20px;
        }

        .splide__arrows {
            display: none;
        }
    }
}

.calc-message {
    display: flex;
    align-items: center;

    @include mediaPhone {
        align-items: flex-start;
    }

    &__img {
        margin-right: 40px;
        max-width: 88px;
        height: auto;

        @include mediaTablet {
            margin-right: 30px;
            max-width: 68px;
        }

        @include mediaPhone {
            margin-right: 15px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 24px;
        line-height: 1.5;
        color: #0A182F;
        max-width: 815px;

        @include mediaTablet {
            font-size: 18px;
        }

        @include mediaPhone {
            font-size: 14px;
        }
    }
}

.block-calc {
    display: flex;
    justify-content: space-between !important;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #D2D7E4;
    border-radius: 5px;
    padding: 25px 30px;
    position: relative;

    @include mediaTablet {
        padding: 20px;
    }
    
    &__count {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaTablet {
            font-size: 14px;
        }
    }

    &__title {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #0A182F;
        margin-top: 25px;

        @include mediaPhone {
            margin-top: 30px;
            font-size: 14px;
        }

        strong {
            display: block;
        }
    }

    &__img {
        position: absolute;
        top: 25px;
        right: 30px;
        z-index: 2;
        max-width: 44px;
        height: auto;

        @include mediaPhone {
            top: 20px;
            right: 20px;
        }
    }
}

.calc-section {
    .splide {
        &__track {
            padding: 10px 0 1px 0;
            margin: -10px 0 0 0;
        }

        &__pagination {
            display: none;
            bottom: -30px;

            @include mediaPhone {
                display: flex;
            }
        }

        &__pagination__page {
            background: #2D58A4;
        }

        &__bullet {
            background: #2D58A4;
        }
    }
}

.calc-filter {
    margin-top: 25px;

    @include mediaTablet {
        margin-top: 20px;
    }

    .calc-filter-group {
        margin-bottom: 18px;

        @include mediaTablet {
            margin-bottom: 20px;
        }
    }
    
    form {
        .button {
            padding: 0 30px;
            height: 50px;
            line-height: 48px;
        }
    }

    &__result {
        margin-top: 60px;

        @include mediaTablet {
            margin-top: 40px;
        }

        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 1.4;
            color: #0A182F;
            margin-bottom: 35px;

            @include mediaTablet {
                font-size: 22px;
                margin-bottom: 25px;
            }
        }
    }
}

.calc-filter-group {
    display: block;
    width: 100%;

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.4;
        color: #0A182F;
        margin-bottom: 5px;

        @include mediaPhone {
            font-size: 16px;
            margin-bottom: 10px;
        }
    }

    &__main {
        display: flex;
        align-items: flex-start;

        @include mediaPhone {
            flex-direction: column;
        }

        label {
            span, .dropdown__title {
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                color: #758296;

                @include mediaPhone {
                    &:not(.radio__main) {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        &.row-x {
            align-items: center;

            @include mediaPhone {
                flex-direction: row;
            }

            label {
                max-width: 100px;
            }

            svg {
                width: 10px;
                height: 10px;
                fill: #758296;
                margin: 0 15px;
                margin-top: 20px;
            }
        }

        .dropdown {
            width: calc(33.333% - 15px);
            margin: 0 15px 15px 0;

            @include mediaPhone {
                width: 100%;
                margin: 0 0 15px 0;
            }

            .dropdown__input-container {
                width: 100%;
                max-width: 100%;
                height: 50px;
                border-radius: 5px;
            }

            .dropdown__select {
                width: 100%;
            }
        }

        .radio {
            margin-right: 40px;
        }


    }
}