@import "variables", "mixin";

.promo {
    .splide {
        visibility: visible;
        &__track {
        }
        &__list {
            width: 100%;
        }
   
      
        &__pagination {
            bottom: -20px;
        }
        &__pagination__page {
            background: #2D58A4;
        }

        &__bullet {
            background: #2D58A4;
        }
    }
}

@include mediaPhone {
    .promo {
        .splide {
            &__list {
                flex-direction: row;
            }
        }
        .article {
            flex: 1 0 100%;
            margin: 0;
        }
        .articles {
            &__list {
                flex-wrap: nowrap;
            }
        }
    }
}
