@import 
"variables",
"mixin";
.rating {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &__stars {
        display: flex;

        svg {
            position: static;
            display: block;
            width: 16px;
            height: 16px;
            margin: 0 4px 0 0;
            fill: #D2D7E4;
            cursor: pointer;
        }
    }

    &__link {
        font-size: 14px;
        text-decoration: underline;
        color: #2D58A4;
        margin: 0 0 0 20px;
    }

    [data-rating="5"] {
        svg:nth-last-of-type(n + 1) {
            fill: #ffa031;
        }
    }
    [data-rating="4"] {
        svg:nth-last-of-type(n + 2) {
            fill: #ffa031;
        }
    }
    [data-rating="3"] {
        svg:nth-last-of-type(n + 3) {
            fill: #ffa031;
        }
    }
    [data-rating="2"] {
        svg:nth-last-of-type(n + 4) {
            fill: #ffa031;
        }
    }
    [data-rating="1"] {
        svg:nth-last-of-type(n + 5) {
            fill: #ffa031;
        }
    }
}
