@import "variables", "mixin";

.favorites {
}

.estimate-button {
}

.favorites-search-container {
    margin: 52px 0 0;
}

.favorites-container {
    margin: 40px 0 0 0;
    
    .item {
        padding: 25px 0 25px 0;

        .left-container {
            display: flex;
            align-items: center;
            margin: 0 0 auto 0;

            .checkbox {
                margin: 0;
                margin-right: 12px;

                &__main {
                    margin: 0;
                }
            }
        }
        &__content {
            align-items: center;
        }

        &__image {
        }

        &__details {
        }
        &__name {
            margin: 0 0 5px 0;
            max-width: initial;
        }
        &__info {
            margin: 0;
            align-items: center;
        }

        &__info-list {
        }

        &__info-col {
            p {
             display: block;
            }

            
        }
    
        &__actions {
            display: flex;
            align-items: center;
            margin: -30px 0 0 28px;
            .action {
                margin: 0 12px 0 0;

                &:last-child(:not(.in_compare)) {
                    margin: 0;
                }

                svg {
                    fill: #2D58A4;
                }

                &.in_compare svg {
                    fill: #D4343C;
                }
            }

            .delete-action {
                svg {
                    fill: #758296;
                }
            }
        }

        &__calc {
            margin: -30px 0 0 auto;
        }
        &__count {
        }

      

        .product {
            &__buy {
                display: flex;
                align-items: center;
            }

            &__buy-info {
                position: static;
            }

            &__buy-info-count {
                margin: 0 10px 0 0;
            }
 

            &__buy-info-price {
                margin: 0 57px 0 0;

                &-total {
                    font-size: 22px;

                    @include mediaTablet {
                        font-size: 18px;
                    }

                    @include mediaPhone {
                        font-size: 16px;
                    }
                }
            }

            &__buy-info-price.checkout {
            }

           

            &__buy-button {
                min-width: 150px;

                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
            }

           
        }
    }
}

@include mediaTablet {
    .favorites-search-container {
        margin: 34px 0 0 0;
    }
    .favorites-container {
        margin: 30px 0 0 0;
         
        

        .item {
            &__content {
                align-items: center;
            }

          
            &__name {
                width: 100%;
                max-width: initial;
            }
            &__info {
                flex-wrap: wrap;
            }

            &__info-list {
                flex-basis: 100%;
            }

            

            &__actions {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                top: 26px;
                margin: 0;
                .action {
                    margin: 0 20px 0 0;

                    &:last-child {
                        margin: 0;
                    }

                    svg {
                        fill: #2D58A4;
                    }
                }

                .delete-action {
                    svg {
                        fill: #758296;
                    }
                }
            }

            &__calc {
                margin: 0 0 0 auto;
            }
            &__count {
            }

             

            .product {
                &__buy {
                    display: flex;
                    align-items: center;
                }

                &__buy-info {
                    position: static;
                }

                &__buy-info-count {
                    // margin: 0 10px 0 0;
                }

                

                &__buy-info-price {
                    margin: 0 30px 0 0;
                }
                &__buy-info-price.checkout {
                }

                &__buy-info-price-total {
                }
                &__buy-info-price-per-unit {
                }

                &__buy-button {
                    min-width: 150px;
                }
 
            }
        }
    }
}

@include mediaPhone {
    .favorites-search-container {
        margin: 30px 0 0 0;
    }
    .favorites-container {
        margin: 20px 0 0 0;
       

        .item {
            .left-container {
                .checkbox {
                    margin: 0 10px 0 0;
                }
            }
            padding: 20px 0;
            &__content {
                align-items: flex-start;
            }

            &__image {
                margin: 0;
            }

            
            &__info {
                flex-wrap: initial;
            }

            &__info-list {
                flex-basis: initial;
            }

            &__actions {
                top: -52px;
                 
            }

          

            .product {
                &__buy {
                }

                &__buy-info {
                    margin: 0;
                }

                &__buy-info-count {
                    margin: 0 0 10px 0;
                }
 

                &__buy-info-price {
                    order: 1;
                    margin: 0;
                }

                &__buy-info-price-total {
                }
                &__buy-info-price-per-unit {
                }

                &__buy-button {
                    display: none;
                }
            }
        }
    }
}
