@import "variables", "mixin";

.breadcrumbs {
    &__list {
      
        padding: 0;
        list-style: none;
    }

    &__item {
        margin: 0;
        display: inline;
    }
    &__item:last-child & {
        &__link {
            color: #758296;
        }
    }

    &__link {
        font-size: 14px;
        line-height: 1.5;
        color: #0a182f;

        &:hover {
            color: #0a182f;
            text-decoration: underline;
        }
    }

    &__ico {
        width: 7px;
        height: 7px;
        margin: 0 8px;
        fill: #758296;
    }
}

// section .breadcrumbs {
.breadcrumbs {
    margin: 36px 0 0 0;
    @include mediaTablet {
        margin: 21px 0 0 0;
    }
    @include mediaPhone {
        margin: 13px 0 0 0;
    }
}

@include mediaPhone {
    .breadcrumbs {
        &__list {
            word-break: break-all;
        }
    }
}
