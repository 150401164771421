@import "variables", "mixin";

.filter {
    &__header {
        display: flex;
        align-items: center;
        background: #EEF7FF;
        border-radius: 5px 5px 0px 0px;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5;
        color: #0A182F;
        padding: 12px 20px;
        margin: -20px;
        margin-bottom: 10px;

        svg {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
    }

    &__checkbox {
        .count {
            color: #758296;
            margin: 0 0 0 6px;
        }
    }
    &__checkbox:last-child {
        // margin: 0 0 10px 0;
    }
    &__item {
        padding: 0 0 10px 0;
    }

    &__title {
        padding: 10px 0 0 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        color: #0a182f;
        cursor: pointer;

        svg {
            display: block;
            width: 9px;
            height: 9px;
            fill: #0a182f;
        }
    }

    &__arrow {
        background: #EEF7FF;
        border-radius: 5px;
        width: 20.91px;
        height: 17.2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__item.active & {
        &__title {
            padding: 10px 0 10px 0;
            color: #2D58A4;
            font-weight: 600;

            svg {
                // transform: rotate(-90deg);
                fill: #2D58A4;
            }
        }
    }

    &__content {
    }
    &__link {
        display: inline-block;
        margin: 0 0 10px 0;

        &:last-child {
            margin: 0;
        }
        font-size: 14px;
        line-height: 1.5;
        color: #0a182f;

        span {
            color: #758296;
        }

        &.back-link {
            color: #2D58A4;
            font-weight: 500;

            svg {
                width: 10px;
                height: 10px;
                margin: 0 12px 0 0;
                fill: #2D58A4;
                transform: rotate(0deg);
            }

            &:hover {
                color: #D4343C;
                svg {
                    fill: #D4343C;
                }
            }
        }
    }

    &__clear-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 11px;
        margin: 20px 0 0 0;
        background: #ffffff;
        font-family: $secondaryFont;
        font-style: normal;
        cursor: pointer;
        border: 1px solid #D4343C;
        border-radius: 5px;
        line-height: 48px;
        height: 50px;
        font-weight: 500;
        font-size: 16px;
        color: #D4343C;


        &:active,
        &:hover {
            color: #D4343C;
            border-color: #D4343C;
        }
    }
}

.filter {
    &__modal {
        display: none;
        width: 100%;
        margin: 34px 0 0 0;
    }
    &__modal-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 11px;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 14px;
        background: #fff;
        color: #2D58A4;
        text-transform: uppercase;
        border: 1px solid #2D58A4;
        box-sizing: border-box;
        border-radius: 3px;
        cursor: pointer;
        svg {
            width: 15px;
            height: 15px;
            fill: #2D58A4;
            margin: 0 10px 0 0;
        }
    }
}
.price-filter {
    width: 100%;
    &__inputs {
        display: flex;
        justify-content: space-between;
    }
    &__input {
        padding: 6px 10px;
        max-width: 47%;
        background: #ffffff;
        border: 1px solid #d9e0ec;
        border-radius: 3px;
        font-size: 12px;

        color: #758296;
    }

    .min-input {
    }
    .max-input {
    }

    &__slider {
        margin: 15px 0 0 0;
    }
}

#mse2_filters {
    .mse2_number_slider.noUi-target {
        margin-bottom: 15px;
    }
}

.noUi-target {
    background: #d9e0ec;
    border-radius: initial;
    border: initial;
    box-shadow: initial;
    width: 100%;
    padding: 0 17px;
    margin: 10px auto 0 auto;
}
.noUi-connect {
    background: #2D58A4;
    margin: 0 -5px;
    width: calc(100% + 10px);
}

.noUi-horizontal .noUi-handle {
    width: 20px;
    height: 20px;
    // right: -10px;
    top: -10px;
}
.noUi-horizontal .noUi-handle-lower {
    // left: 14px;
    right: 0;
}
.noUi-horizontal .noUi-handle-upper {
    right: -20px;
}
.noUi-horizontal {
    height: 2px;
}
.noUi-handle {
    background: #fff;
    cursor: default;
    background: #2D58A4;
    border: 4px solid #ffffff;
    border-radius: 10px;
    box-shadow: initial;
}
.noUi-handle::after,
.noUi-handle::before {
    display: none;
}

@include mediaTablet {
    .filter {
        &__modal {
            display: block;
        }
        &__modal-button {
            svg {
            }
        }
    }
}
