@import "variables", "mixin";

.promo .article {
    &__image {
        min-width: 150px;
        object-fit: cover;
        flex: 0;
    }
}

@include mediaTablet {
    .promo .article {
        flex-basis: 100%;

        margin: 0 0 25px 0;

        &:last-child {
            margin: 0;
        }
    }
    .home-page .promo .article:nth-last-child(-n + 2) {
        display: none;
    }
}
@include mediaPhone {
    .promo .article {
        flex-direction: column;
        margin: 0 0 15px 0;
        padding: 20px;

        &__text-date-expires {
            justify-content: center;
        }
        &__image {
            width: 100%;
        }
        &__text {
            padding: 15px 0 0 0;
        }
        &__text-title {
            -webkit-line-clamp: 3;
        }

        &__text-description {
            -webkit-line-clamp: 2;
        }
    }
     .home-page .promo .article:nth-last-child(-n + 2) {
        display: flex;
    }
}
