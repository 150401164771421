.tabs {
    &__caption {
        list-style-type: none;
        padding: 0;
        margin-bottom: 40px;
        display: flex;

        @include mediaTablet {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            margin-bottom: 20px;
        }

        @include mediaPhone {
            margin: 0;
            margin-bottom: 14px;
        }

        li {
            margin-bottom: 0;

            @include mediaTablet {
                width: calc(50% - 30px);
                margin: 0 15px 15px 15px;
            }

            @include mediaPhone {
                width: 100%;
                margin: 0 0 10px 0;
            }

            &:not(:last-child) {
                margin-right: 40px;

                @include mediaTablet {
                    margin-right: 15px;
                }

                @include mediaPhone {
                    margin-bottom: 10px;
                    margin-right: 0;
                }
            }

            &.active {
                .tab-project {
                    &__img {
                        background-color: #2D58A4;
                    }
                }
            }
        }
    }

    &__content {
        &.active {
            display: block;
        }
        &.hide {
            visibility: hidden;
            opacity: 0;
            height: 0;
        }
    }
}

.tab-project {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #0A182F;
    cursor: pointer;

    &__img {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #D2D7E4;
        margin-right: 15px;

        img {
            width: 15px;
            height: 15px;
            font-size: 0;
        }
    }

    span {
        display: block;
    }
}

.block-project {
    position: relative;
    
    &__banner {
        display: block;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 20px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(27.25deg, rgba(0, 0, 0, 0.7) 15.88%, rgba(0, 0, 0, 0) 49.36%);
        }

        &:hover {
            &::before {
                background: linear-gradient(27.25deg, rgba(45, 88, 164, 0.7) 15.88%, rgba(45, 88, 164, 0) 49.36%);
            }
        }
    }

    &__date {
        line-height: 41px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 41px;
        padding: 0 10px;
        font-weight: 600;
        font-size: 14px;
        position: absolute;
        left: 25px;
        top: 25px;
        z-index: 2;
        color: #2D58A4;

        @include mediaTablet {
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            left: 20px;
            top: 20px;
        }
    }

    &__info {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        padding: 20px 25px;
        width: 100%;
        color: #fff;
        line-height: 1.4;

        @include mediaTablet {
            padding: 20px;
            font-size: 14px;
        }

        strong {
            display: block;
        }
    }

    &__title {
        display: block;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaTablet {
            font-size: 16px;
        }
    }

    &__link {
        display: inline-block;
        margin-top: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        text-decoration: underline;
        color: #2D58A4;

        @include mediaTablet {
            margin-top: 5px;
        }
    }
}

.project-section {
    &__min-title {
        font-size: 24px;
        
        @include mediaTablet {
            font-size: 22px;
        }

        @include mediaPhone {
            font-size: 20px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        @include mediaTablet {
            margin: 0 -5px;
        }

        @include mediaPhone {
            margin: 0;
            display: block;
        }

        .splide__slide {
            width: calc(50% - 30px);
            margin: 0 15px 40px 15px;

            @include mediaTablet {
                width: calc(50% - 10px);
                margin: 0 5px 30px 5px;
            }

            @include mediaPhone {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.projects {
    &-content {
        max-width: 100%;
        padding: 0 0 85px 0;
    
        @include mediaTablet {
            padding: 0 0 65px 0;
        }

        @include mediaPhone {
            padding: 0 0 50px 0;
        }

        h3 {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 24px;

            @include mediaTablet {
                font-size: 22px;
            }
    
            @include mediaPhone {
                font-size: 20px;
            }
        }

        p {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
    }
}

.project-content {
    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 35px;

        @include mediaTablet {
            margin-bottom: 25px;
        }

        @include mediaPhone {
            margin-bottom: 15px;
        }

        .info-item {
            display: inline-flex;
            align-items: center;
            margin-right: 40px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;
            color: #758296;
            margin-bottom: 10px;

            @include mediaPhone {
                font-size: 12px;
                margin-right: 17px;
            }

            svg {
                width: 18px;
                height: 18px;
                fill: #758296;
                margin-right: 10px;

                @include mediaPhone {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &__img {
        border-radius: 5px;
        margin-bottom: 40px;

        @include mediaTablet {
            margin-bottom: 30px;
        }

        @include mediaPhone {
            margin-bottom: 20px;
        }
    }
}