.filter {
    .accordion.active & {
        &__content {
            display: flex;
            min-height: 60px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
    }
}
