@import 
"variables",
"mixin";

.splide {
    
    &__slides {
        padding: 10px 0 0 0;
        white-space: initial;
    }

    &__arrow {
        padding: 0;
        border: 0;
        width: 44px;
        height: 44px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.6);
        box-shadow: initial;
        text-shadow: initial;
        transition: all 0.3s linear; 
        background: #FFFFFF;
        border: 1px solid #D2D7E4;
    }
    &__arrow:active,
    &__arrow:hover {
        background: #fff;
        transition: all 0.3s linear;
    }
    &__arrow svg {
        fill: #2D58A4;
        width: 12px;
        height: 12px;

        &.big-ico {
            width: 44px;
            height: 44px;
        }
    }
    &__arrow--prev svg {
        transform: initial;
    }
    &__arrow--next svg {
        transform: rotate(180deg);
    }
    &__arrow--prev {
        left: -104px;
    }
    &__arrow--next {
        right: -104px;
    }

    &__pagination {
        display: none;
        align-items: center;
        bottom: 30px;
    }
    &__pagination li {
        margin: 0px 5px;
    }

    &__pagination__page {
        width: 5px;
        height: 5px;
        background: #fff;
        transition: all 0.2s linear;
        opacity: 1;
    }
    &__pagination__page.is-active {
        transform: scale(1.8);
        background: #fff;
        opacity: 1;
    }
    
    &.history__slider, &.serf__slider, &.reviews__slider {
        .splide__arrows {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            top: -64px;
            right: 0;
            @include mediaTablet {
                display: none;
            }
        }
        .splide__arrow {
            top: unset;
            transform: none;
            position: static;
            &:first-child {
                margin-right: 15px;
            }
            svg {
                width: 12px;
                height: 12px;
            }
        }
        .splide__track {
            margin-bottom: 20px;
        }
    }
    
    @include mediaTablet {
        &__pagination {
            display: inline-flex;
        }
    }
}
.baner {
    .splide__arrow--prev {
        right: calc(50vw + 678px);
        left: unset;
        @include media(1500px) {
            right: calc(50vw + 610px);
        }
        @include media(1350px) {
            display: none;
        }
    }
    .splide__arrow--next {
        left: calc(50vw + 678px);
        right: unset;
        @include media(1500px) {
            left: calc(50vw + 610px);
        }
        @include media(1350px) {
            display: none;
        }
    }
    .splide__pagination {
        display: inline-flex;
    }
}
   

@include media(1400px) {
    .splide {
        &__arrow--prev {
            left: -60px;
        }
        &__arrow--next {
            right: -60px;
        }
    }
}
@include media(1240px) {
    .splide {
        &__pagination {
            display: flex;
        }
    }
    .history__slider .splide__pagination {
        display: none;
        @include mediaTablet {
            display: flex;
        }
    }
}
