.checkout .item {
    &__info {
        margin: 0;
    }

    &__count {
        margin: -10px 100px 0px auto;
    }

    &__calc {
        margin: -20px 0 0 0;
    }
}

@include mediaTablet {
    .checkout .item {
        &__count {
            margin: -20px 100px 0px auto;
        }

        &__calc {
            margin: -30px 0 0 0;
        }
    }
}

@include mediaPhone {
    .checkout .item {
        padding: 12px 0;
        &__count {
            margin: 0 100px 0px auto;
        }

        &__calc {
            margin:  0;
        }
    }
}
