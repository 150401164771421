.dropdown {
    &__input-container {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px;
        max-width: 140px;
 
        height: 40px;
        border: 1px solid #d9e0ec;
        box-sizing: border-box;
        border-radius: 3px;

        &:hover {
            border: 1px solid #0a182f;
        }
    }

    &.active & {
        &__input-container {
            border: 1px solid #d9e0ec;
        }
    }

    &__select {
        background: #ffffff;
        border: 0;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        color: #0a182f;
    }

    &__title {
        font-size: 14px;
        color: #758296;
        margin: 0 0 5px 0;
    }
    &__input {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        background: #ffffff;
        border: 0;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;

        color: #0a182f;
        cursor: pointer;
        outline: none;

        &:hover {
            border: 0;
        }
    }

    &__input-ico {
        position: absolute;
        right: 12px;
        top: 50%;
        width: 8px;
        height: 8px;
        fill: #0a182f;
        pointer-events: none;
        transform: rotate(90deg) translateX(-50%);
    }

    &__input:focus + .dropdown__input-ico {
        transform: rotate(-90deg) translateX(50%);
    }
 

    &__list {
        position: absolute;
        top: calc(100% - 2px);
        left: -1px;
        width: calc(100% + 2px);
        display: none;
        // margin: 40px 0 0 0;
        padding: 16px 12px;
        background: #ffffff;
        border: 1px solid #d9e0ec;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        list-style: none;
        max-height: 220px;

        z-index: 100;
        overflow: auto;

        @include scrollbar(#0a182f, #edf2fb, 0, 10px);
    }

    &.active & {
        &__list {
            display: block;
        }
    }

    &__item {
        position: relative;
        margin: 0 0 8px;
        cursor: pointer;
        opacity: 1;

        &:last-child {
            margin: 0;
        }
    }

    &___item:hover {
    }

    .expandUp {
        top: initial;
        bottom: 0;
    }
}
