// top-bar section
@import "variables", "mixin";

.info-page {
    &__section {
        margin: 0 0 30px 0;

        &:last-child {
            margin: 0;
        }
    }

    .section__main {
        max-width: 940px;
        margin: 0 auto;
    }

    &__content {
        max-width: 940px;
        margin: 0 auto;
        margin-top: 40px;

        @include mediaPhone {
            margin-top: 30px;
        }

        h2 {
            margin-bottom: 20px;

            @include mediaTablet {
                margin-bottom: 10px;
                font-size: 18px;
            }
        }
    }

    &__date {
        display: flex;
        align-items: center;
        margin: 20px 0 15px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 18px;
        color: #758296;

        svg {
            margin: 0 10px 0 0;
            fill: #758296;
        }
    }
    &__intro-image {
        margin: 0 0 30px 0;
 
    }

    &__section-title {
        display: flex;
        align-items: flex-start;
        margin: 0 0 25px 0;
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #0a182f;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            margin: 0 20px 0 0;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;

            background: #2D58A4;
            border-radius: 3px;
        }
    }

    p {
        margin: 0 0 20px;
        font-size: 16px;
        line-height: 1.5;
        color: #0a182f;

        &:last-child {
            margin: 0;
        }
    }

    ul, ol {
        font-size: 16px;
        line-height: 1.5;
        color: #0a182f;
    }

    ul {
        li {
            margin-bottom: 5px;
        }
    }

    h4 {
        margin: 0 0 20px;
        font-size: 18px;
        font-weight: 700;
        color: #0a182f;
    }

    .promo-title {
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;

        letter-spacing: 0.03em;

        color: #758296;
    }
} 

.info-page-container {
    max-width: 942px;
    margin: 0 auto;
}

.payment-cards-list {
    margin: 0 0 13px;
}

@include mediaTablet {
    .info-page {
        &__date {
            font-size: 16px;
        }

        p {
            font-size: 14px;
        }
    }
    .promo-title {
        font-size: 22px;
    }
}
@include mediaPhone {
    .info-page {
        &__date {
            font-size: 14px;
        }
    }
    .promo-title {
        font-size: 18px;
    }
}
