@import "variables", "mixin";
.tabs {
    margin: 40px 0 0 0;

    &__list {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
    }

    @include mediaPhone {
        margin: 30px 0 0 0;
    }
}

.tab {
    display: flex;
    position: relative;
    cursor: pointer;
    max-height: 120px;
    width: 100%;
    border-top: 1px solid #d9e0ec;
    width: 33.333%;
    margin: 0;

    @include mediaTablet {
        max-height: 106px;
        width: 50%;
        max-width: initial;
    }

    @include mediaPhone {
        flex: 0 1 calc(100%);
        max-height: 80px;
    }

    &__title {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 40px 40px 40px 0;
        background: #ffffff;
        font-size: 16px;
        line-height: 1.5;
        color: #0a182f;
        font-weight: 500;

        @include mediaTablet {
            padding: 30px 30px 30px 0;
        }

        @include mediaPhone {
            padding: 20px 0 20px 0;
            font-size: 14px;
            line-height: 1.4;
        }

        img {
            margin: 0 18px 0 0;
            max-width: 44px;
            height: auto;

            @include mediaTablet {
                max-width: 44px;
            }

            @include mediaPhone {
                max-width: 34px;
                margin: 0 15px 0 0;
            }
        }

        img.if-active {
            display: none;
            max-width: 44px;
            height: auto;
        }
    }
    &__title:hover {
        color: #2D58A4;
    }
    &__content {
        position: absolute;
        display: none;
        width: 100%;
        padding: 30px;
        background: #ffffff;
        border: 1px solid #2D58A4;
        cursor: initial;
        z-index: 1;

        @include mediaTablet {
            padding: 20px 25px;
        }
        @include mediaPhone {
            padding: 0 0 15px 0;
            border-right: 0;
            border-left: 0;
            border-top: 0;
        }
    }

    &__content-list {
        // flex-basis: 100%;
        margin: 0 80px 20px 0;
        padding: 0;
        list-style: none;

        @include mediaTablet {
            margin: 0 60px 15px 0;
        }
        @include mediaPhone {
            margin: 0 0 20px 0;
        }
    }
    &__content-list:last-child {
        margin: 0;
    }

    &__content-item {
        margin: 0 0 14px 0;

        @include mediaPhone {
            margin: 0 0 10px 0;
        }
    }
    &__content-item.title & {
        &__content-link {
            font-weight: 500;
        }
    }
    &__content-link {
        margin: 0 0 10px 0;
        font-weight: 400;
        font-size: 16px;
        color: #0a182f;

        @include mediaTablet {
            font-size: 14px;
            font-weight: 500;
        }

        &:hover {
            color: #2D58A4;
            text-decoration: underline;
        }
    }
    &.active & {
        &__title {
            color: #2D58A4;

            @include mediaTablet {
                font-weight: 700;
            }
        }
        &__title:after {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            background: #ffffff;
            bottom: -9px;
            left: 11px;
            z-index: 2;
            transform: rotate(45deg);
            border: 1px solid #2d58a4;
            border-right: 0;
            border-bottom: 0;

            @include mediaPhone {
                content: none;
            }
        }
    }
    &.active & {
        &__content {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            @include mediaPhone {
                flex-wrap: wrap;
            }
        }
    }
}
