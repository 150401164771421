@import "variables", "mixin";

.reg {
    &.receipt-place {
        margin: 25px 0 0 0;
    }
}

.reg-form-container {
    margin: 40px 0 0;
    .title {
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #0a182f;
    }
}

.reg-form {
    margin: 25px 0 0;
    .input-fields {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        margin: 0 0 44px;
        .fields {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .field {
            flex: 1;
            width: 100%;
            max-width: 461px;
            min-width: 461px;
            margin: 0 20px 20px 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    .captcha.fields {
        border-top: 1px solid #d9e0ec;
        margin: 45px 0 13px;
        padding: 45px 0 0;
    }

    .reg-button {
        width: 100%;
        max-width: 300px;
        margin: 30px 0 0;
    }
}
.password-info {
    max-width: initial;
    width: 100%;

    li {
        font-size: 12px;
        line-height: 1.5;
        color: #758296;
        margin: 0 0 6px;
    }
}
.quiz {
    width: 100%;

    max-width: 942px;

    &__title {
        margin: 0 0 25px;
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;

        color: #0a182f;
    }

    &__questions {
        .title {
            margin: 0 0 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            color: #0a182f;
        }
    }
    .fields {
    }

    .other.field {
        display: flex;
        align-items: center;
        .radio {
            margin: 0 10px 20px 0;
        }
        .text {
            max-width: 170px;
            // margin: 0 0 5px;
            span {
                font-size: 12px;
                color: #758296;
            }
        }
        input {
            padding: 4px;
        }
    }

    .input {
        margin: 0 0 12px;
    }
}

@include mediaTablet {
    .reg {
        &.receipt-place {
            margin: 20px 0 0 0;
        }
    }
    .reg-form-container {
        margin: 24px 0 0;
        .title {
            font-size: 22px;
        }
    }

    .reg-form {
        margin: 20px 0 0 0;

        .input-fields {
            margin: 0 0 30px;
            .fields {
            }

            .field {
                max-width: initial;
                margin: 0 10px 10px 0;
                min-width: 347px;
            }
        }
        .captcha.fields {
            margin: 31px 0 12px;
            padding: 31px 0 0;
        }
    }

    .quiz {
        &__title {
            margin: 0 0 15px;
            font-size: 22px;
        }

        &__questions {
            .title {
                display: block;
                margin: 0 0 15px;
                font-size: 14px;
            }
        }
    }
}

@include mediaPhone {
    .reg {
        &.receipt-place {
            margin: 23px 0 0 0;
        }
    }
    .reg-form-container {
        margin: 24px 0 0;
        .title {
            display: none;
        }
        .quiz {
            &__questions {
                .title {
                    display: block;
                }
            }
        }
    }

    .reg-form {
        margin: 20px 0 0 0;

        .input-fields {
            flex-direction: column;

            .fields {
                margin: 0;
                width: 100%;
                max-width: initial;
            }

            .field {
                margin: 0 0 10px;
                min-width: 290px;
            }
        }
        .reg-button {
            max-width: initial;
        }

        .captcha.fields {
            margin: 12px 0 12px;
            padding: 12px 0 0;
        }
    }

    .quiz {
        padding: 0 0 43px;
        margin: 0 0 12px;
        &__title {
            margin: 0 0 10px;
            font-size: 18px;
        }

        &__questions {
            .title {
                margin: 0 0 10px;
                font-size: 14px;
            }
        }
    }
}
