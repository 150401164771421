@import "variables", "mixin";

.account-main {
    display: flex;
    justify-content: space-between;
    margin: 55px 0 0 0;
}

.account-sidebar {
    flex: 1;
    padding: 35px 45px;
    max-width: 345px;
    margin: 0 55px auto 0;
    background: #EEF7FF;
    border-radius: 5px;
}

.account-menu {
    &__list {
    }
}

.account-content {
    flex: 1;
    max-width: 780px;

    p {
        margin-bottom: 15px;
    }

    h2 {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    blockquote {
        margin: 0;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.5;

        @include mediaTablet {
            font-size: 18px;
        }

        @include mediaPhone {
            font-size: 16px;
        }
    }
}

.account-accordion {
    svg {
        width: 10px;
        height: 10px;
    }
    .accordion__title {
        display: none;
        .button {
            flex-grow: 1;
            margin: 0;
            padding: 20px 0;
        }

        svg {
            margin: 0 0 0 15px;
            transform: rotate(90deg);
            fill: #2D58A4;
        }
    }

    .accordion__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.calc {
            .button {
                align-items: flex-start;
                line-height: 1.2;

                &:last-child {
                    margin: 0 !important;
                }

                svg {
                    width: 16px;
                    height: 17px;
                }
            }
        }
    }
    .button {
        margin: 0 0 20px 0;
        color: #0a182f;
        svg {
            fill: #0a182f;
            width: 16px;
            height: 16px;
            margin: 0 10px 0 0;
            transform: initial;
        }

        &.active,
        &:hover {
            color: #2D58A4;
            text-decoration: none;

            svg {
                fill: #2D58A4;
            }
        }
    }
    &.active {
        .accordion__content {
            display: flex;
        }
        .accordion__title {
            svg {
                transform: rotate(-90deg);
            }
            .button {
                svg {
                    transform: rotate(0);
                }
            }
        }
    }
}

// История заказов
.order-history {
    input {
        max-height: 40px;
    }
    .title {
        margin: 0 0 25px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 32px;

        letter-spacing: 0.03em;

        color: #0a182f;
    }
    .period {
        display: flex;
        align-items: center;
        margin: 0 0 28px 0;

        &__between-title {
            margin: 0 0 5px 0;
            font-size: 14px;
            color: #758296;
        }
        &__between {
            margin: 0 0 0 30px;
            .fields {
                display: flex;

                .field {
                    display: flex;
                    align-items: center;
                    margin: 0 10px 0 0;
                    font-size: 14px;
                    color: #0a182f;
                    &:last-child {
                        margin: 0;
                    }
                }

                input {
                    max-width: 112px;
                    margin: 0 0 0 10px;
                    padding: 12px;
                    text-align: center;
                }
            }
        }
    }
    .dropdown {
        

        &__input-container {
            max-width: 200px;
        }
 
    }

    .status {
        margin: 0 0 45px 0;
        display: flex;
        align-items: center;
        .checkbox {
            margin: 0 30px 0 0;
            &__label {
            }

            &__input {
            }

            &__main {
            }
            .text {
                display: flex;
                align-items: center;

                svg {
                    margin: 0 10px 0 0;
                }
            }
        }
    }
    .list {
        &__items {
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 25px 0;
            border-top: 1px solid #d9e0ec;
            border-bottom: 1px solid #d9e0ec;
            font-size: 16px;
            color: #0a182f;

            &:first-child {
                padding-top: 0;
                border-top: 0;
                font-family: $secondaryFont;
                font-weight: bold;
                font-size: 18px;
                letter-spacing: 0.03em;
                color: #758296;
            }

            &:hover {
                svg {
                    fill: #0a182f;
                }
            }

            &.hide {
                display: none;
            }
        }
    }
    .order-number {
        flex: 1;
    }
    .order-sum {
        flex: 1;

        .sign {
            font-weight: 400;
        }
    }
    .order-status {
        display: flex;
        flex: 1;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 20px;
            background: #f8faff;
            border-radius: 120px;
        }
        svg {
            margin: 0 7px 0 0;
        }
        &:hover {
            fill: #0a182f;
        }
    }
    svg {
        width: 11px;
        height: 11px;
        fill: #0a182f;
    }
}
//END История заказов

// Просмотр заказа
.order-detail {
    .back-link {
        margin: 0 0 25px 0;
        display: flex;

        a {
            display: flex;
            align-items: center;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
        }

        svg {
            width: 10px;
            height: 10px;
            margin: 0 15px 0 0;
            transform: rotate(180deg);
        }
    }

    .title {
        margin: 0 0 20px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 24px;

        letter-spacing: 0.03em;

        color: #0a182f;
    }

    .summary {
        display: flex;
        align-items: center;
        margin: 0 0 25px 0;
        &__item {
            display: flex;
            margin: 0 30px 0 0;
            font-size: 12px;
            color: #758296;
            span {
                color: #0a182f;
            }
            &:last-child {
                margin: 0 0 0 auto;
                font-family: $secondaryFont;
                font-weight: 800;
                font-size: 18px;
                color: #0a182f;
            }
        }
    }
}
//END  Просмотр заказа

//Программа лояльности
.loyalty {
    .title {
        display: flex;
        justify-content: space-between;
        margin: 0 0 41px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 32px;
        letter-spacing: 0.03em;

        color: #0a182f;

        span {

        }

        .button {
            letter-spacing: 0;
        }
    }
    &__programm {
        padding: 30px 40px 40px 40px;
        border: 1px solid #d9e0ec;
    }

    &__programm-title {
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.03em;
        margin-bottom: 10px;
        color: #758296;
    }

    &__programm-percentage {
        display: flex;
        align-items: baseline;
        margin: 0 0 15px 0;
        span {
            margin: 0 20px 0 0;
            font-family: $secondaryFont;
            font-weight: 800;
            font-size: 30px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #758296;
            &.active {
                font-size: 80px;
                color: #2D58A4;
            }
        }
    }
}

.progress {
    p {
        margin: 0 0 20px 0;
        font-size: 14px;
        color: #0a182f;
        .sign {
            font-weight: initial;
        }
    }
    &__bar {
        display: flex;
        padding: 4px;
        width: 100%;
        min-height: 20px;
        background: #d9e0ec;
        border-radius: 3px;
    }

    &__bar-line {
        min-height: 12px;
        min-width: 20px;
        background: #2D58A4;
        border-radius: 3px;
    }

    &__values {
        display: flex;
        margin: 10px 0 0 0;
        justify-content: space-between;
        font-size: 12px;
        color: #0a182f;
        p {
            margin: 0;
        }
    }

    &__values-current {
    }

    &__values-max {
    }
}
//END Программа лояльности

// калькулятор
.calc {
    .title {
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 32px;
        color: #0a182f;

        @include mediaTablet {
            font-size: 22px;
            margin: 0 0 5px 0;
        }

        @include mediaPhone {
            font-size: 20px;
        }
    }
}
//END калькулятор

// Сметы
.estimates {
    &__title {
        display: flex;
        justify-content: space-between;
        margin: 0 0 30px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 32px;
        letter-spacing: 0.03em;
        color: #0a182f;
    }
}

.estimates-actions {
    display: flex;
    justify-content: space-between;
    padding: 0 0 20px 0;
    margin: 0 0 30px 0;
    border-bottom: 3px solid #d9e0ec;
}
.estimates-action {
    font-size: 14px;
    svg {
        width: 14px;
        height: 14px;
        margin: 0 10px 0 0;
    }
    &:last-child {
        margin: 0;
    }
}

.estimates-list {
    margin: 0 0 40px 0;
}
.estimate {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #d9e0ec;
    &:first-child {
    }
    &:first-child & {
        &__name {
            margin: 0 -30px 0 30px;
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #0a182f;
            text-decoration: none;
        }

        &__date,
        &__sum {
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 18px;
            color: #0a182f;
            text-decoration: none;
        }
    }
    &__name {
        display: flex;
        align-items: center;
        flex: 1;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        color: #2D58A4;

        a {
            color: #2D58A4;
            text-decoration: underline;
            &:hover {
                color: #2D58A4;
                text-decoration: none;
            }
        }

        .checkbox {
            margin: 0;
        }
    }

    &__date {
        flex: 1;
        max-width: 120px;
        font-size: 14px;
        color: #0a182f;

        .title {
            color: #758296;
            display: none;
        }
    }

    &__sum {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        max-width: 220px;
        font-size: 14px;
        color: #0a182f;
        .title {
            color: #758296;
            display: none;
        }
        .sign {
            font-weight: 400;
        }
    }
}

.estimates-howto {
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__title {
        display: flex;
        margin: 0 0 30px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 18px;

        letter-spacing: 0.03em;

        color: #0a182f;
    }

    &__item {
        flex: 0 1 calc(33% - 30px);
        margin: 0 30px 20px 0;
        .title {
            margin: 0 0 10px 0;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 24px;
            color: #2D58A4;

            svg {
                margin: 0 0 0 27px;
                flex-shrink: 0;
                width: 60px;
                fill: #d9e0ec;
            }
        }
        .desciption {
            font-size: 14px;
            line-height: 1.5;

            color: #0a182f;
        }
    }
}

.products-estimates {
    &__title {
        margin: 0 0 30px 0;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.03em;

        color: #0a182f;
    }

    &__list {
        display: flex;
        flex-direction: column;

        margin: 0 0 40px 0;
    }

    &__item {
    }

    &__total {
        margin: 30px 0 0 0;

        font-family: $secondaryFont;
        font-weight: 800;
        font-size: 18px;
        color: #0a182f;
        text-align: right;
    }
}
.products-estimate {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-bottom: 1px solid #d9e0ec;
    font-family: Gilroy;
    font-size: 14px;
    color: #0a182f;

    .col {
        .title {
            display: none;
            color: #758296;
        }
    }

    &:first-child {
        padding-top: 0;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 16px;
        color: #0a182f;
    }
    &:first-child & {
        &__sum {
            padding-top: 0;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
            color: #0a182f;
            text-align: right;
        }
    }
    &__number {
        flex: 1;
        max-width: 28px;
    }

    &__code {
        flex: 1;
        max-width: 90px;
    }

    &__description {
        flex: 1;
        max-width: 240px;
    }

    &__unit {
        flex: 1;
        max-width: 46px;
        text-align: center;
    }

    &__count {
        flex: 1;
        max-width: 72px;
        text-align: center;
    }

    &__sum {
        flex: 1;
        margin: 0 60px 0 0;
        display: flex;
        flex-direction: column;
        max-width: 100px;
        text-align: right;
        font-family: $secondaryFont;
        font-weight: 800;
        font-size: 18px;

        color: #0a182f;
        .bottom {
            font-size: 12px;
            color: #758296;
        }
    }
    &__delete-button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, 70%);
        svg {
            fill: #758296;
        }

        &:hover {
            svg {
                fill: #D4343C;
            }
        }
    }
}

.jobs-estimates {
    &__title {
        display: flex;
        align-items: center;
        margin: 0 0 30px 0;
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.03em;

        color: #0a182f;
    }
    &__add-button {
        font-size: 14px;
        margin: 0 0 0 30px;
        svg {
            margin: 0 10px 0 0;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
    }

    &__total {
        margin: 30px 0 0 0;

        font-family: $secondaryFont;
        font-weight: 800;
        font-size: 18px;
        color: #0a182f;
        text-align: right;
    }
}
.jobs-estimate {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #d9e0ec;

    .col {
        .title {
            font-size: 12px;
            line-height: 1.5;
            color: #758296;
        }
    }
    &:first-child {
        padding-top: 0;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 16px;
        color: #0a182f;
    }
    &:first-child & {
        &__sum {
            padding-top: 0;
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
            color: #0a182f;
            text-align: right;
        }
    }
    &__number {
        flex: 1;
        max-width: 28px;
    }
    &__description {
        flex: 1;
        max-width: 284px;

        span {
            display: none;
        }
    }

    &__count {
        flex: 1;
        max-width: 89px;
        input {
        }
        span {
            display: none;
        }
    }

    &__price {
        flex: 1;
        max-width: 89px;

        input {
            color: #0a182f;
        }
        span {
            display: none;
        }
    }

    &__sum {
        flex: 1;
        margin: 0 60px 0 0;
        font-family: $secondaryFont;
        font-weight: 800;
        font-size: 18px;
        max-width: 100px;
        text-align: right;
        color: #0a182f;
    }

    &__delete-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        svg {
            fill: #758296;
        }

        &:hover {
            svg {
                fill: #D4343C;
            }
        }
    }
}

.account.estimates {
    .account-content {
        max-width: initial;
    }
}

.estimates-manager {
    &__tabs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 45px 0;
        margin: 0 10px 0 10px;
        max-width: 380px;
        background: #ffffff;
        border: 1px solid #d9e0ec;

        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #0a182f;

        svg {
            width: 20px;
            height: 20px;
            margin: 0 15px 0 0;
            fill: #0a182f;
        }

        &:last-child {
            margin: 0;
        }
    }

    &__tab.active,
    &__tab:hover {
        color: #2D58A4;
        border-color: #2D58A4;
        svg {
            fill: #2D58A4;
        }
    }

    &__tab.new.active ~ & {
        &__tabs-content {
            .new-tab {
                display: block;
            }
        }
    }
    &__tab.update.active ~ & {
        &__tabs-content {
            .update-tab {
                display: block;
            }
        }
    }
    &__tab.add.active ~ & {
        &__tabs-content {
            .add-tab {
                display: block;
            }
        }
    }

    &__tabs-content {
        flex-basis: 100%;
        margin: 0 15px;
        .tab-content {
            display: none;
            margin: 40px 0 0 0;
            .title {
                margin: 0 0 20px 0;
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                letter-spacing: 0.03em;

                color: #0a182f;
            }

            .radio__label {
                font-size: 16px;
            }
            .radio.field {
                margin: 0 0 12px 0;
            }
        }
        .new-tab {
            input {
                margin: 0 0 15px 0;
            }
            p {
                font-size: 14px;

                color: #758296;
            }
        }
        .update-tab {
        }
        .add-tab {
        }

        .button {
            margin: 30px 0 0 0;
            padding: 25px 0;
            min-width: 380px;
        }
    }
}

.estimate-manager-button {
    font-size: 16px;
}

// END Сметы

.page-serf {
    h3 {
        font-weight: 700;
        font-size: 24px;

        @include mediaTablet {
            font-size: 18px;
        }
    }

    .button {
        margin-top: 30px;
    }
}

.sec-serf {
    padding-top: 60px;

    @include mediaTablet {
        padding-top: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        @include mediaTablet {
            margin-bottom: 20px;
        }

        h3 {
            margin: 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 1.4;

            @include mediaTablet {
                font-size: 18px;
            }
        }

        .splide__arrows {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .splide__arrow {
                position: static;
                margin-left: 15px;
                transform: none;
            }
        }
    }

    &__list {
        
        &--utp-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -7px;

            @include mediaPhone {
                flex-direction: column;
                margin: 0;
            }

            .block-min-utp {
                width: calc(50% - 14px);
                margin: 0 7px 20px 7px;

                @include mediaPhone {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }

        .accordion {
            margin-top: -1px;
        }

        .about-section__video {
            margin: 0;
            margin-bottom: 20px;
        }

        &--video {
            display: flex;
            align-items: center;
            margin: 0 -10px;

            @include mediaPhone {
                flex-direction: column;
                margin: 0;
            }

            .about-section__video {
                width: calc(50% - 20px);
                margin: 0 10px 20px 10px;

                @include mediaPhone {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }

        &--utp-list-title {
            .block-min-utp {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.block-serf {
    display: flex;
    flex-direction: column;
    position: relative;

    &__link {
        display: block;
        padding: 10px;
        border-radius: 5px;
        overflow: hidden;
        background: #FFFFFF;
        border: 1px solid #D2D7E4;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #0A182F;
        margin-top: 15px;
    }
}

.block-review {
    display: flex;
    flex-direction: column;
    background: #EEF7FF;
    border-radius: 5px;
    padding: 40px;

    @include mediaTablet {
        padding: 24px;
    }

    @include mediaPhone {
        padding: 20px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100px;
        flex-shrink: 0;

        @include mediaTablet {
            margin-bottom: 15px;
        }

        @include mediaPhone {
            flex-direction: column;
        }
    }

    &__img {
        max-width: 100px;
        width: 100%;
        border-radius: 5px;

        @include mediaPhone {
            max-width: 80px;
        }
    }

    &__info {
        display: flex;
        align-items: center;

        @include mediaPhone {
            width: 100%;
        }

        &-text {
            margin-left: 15px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0.03em;
        color: #0A182F;
        margin-bottom: 7px;

        @include mediaTablet {
            font-size: 16px;
        }

        @include mediaPhone {
            font-size: 14px;
        }
    }

    &__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: #758296;
        margin-bottom: 16px;

        @include mediaPhone {
            font-size: 12px;
        }
    }

    &__logo {
        margin-left: 30px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 210px;
        height: 120px;

        @include mediaPhone {
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
            height: 60px;
        }
        
        img {
            max-width: 55%;
            max-height: 80%;
            object-fit: cover;
            height: auto;

            @include mediaPhone {
                max-height: 50%;
            }
        }
    }

    &__text {
        margin-bottom: 0 !important;
    }

    &__footer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mediaTablet {
            margin-top: 15px;
        }

        @include mediaPhone {
            flex-direction: column;
        }
    }

    &__files {
        @include mediaPhone {
            width: 100%;
        }

        .title {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            color: #758296;
            margin-bottom: 10px;
        }

        .files {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .button {
                margin: 0;
                margin-right: 20px;
                color: #0A182F;
                text-decoration: underline;
                font-size: 16px;

                svg {
                    fill: #0A182F;
                }

                span {
                    color: #0A182F;
                }
            }
        }
    }

    &__btn {
        @include mediaPhone {
            width: 100%;
            margin-top: 15px;

            .button {
                width: 100%;
            }
        }

        svg {
            margin-top: 1px;
            margin-left: 10px;
            width: 11px;
            height: 11px;
        }
    }

    &--min {
        padding: 35px;

        @include mediaTablet {
            padding: 25px;
        }

        @include mediaPhone {
            padding: 20px;
        }

        .block-review {
            &__img {
                max-width: 100px;
            }
        }
    }
}

.block-min-utp {
    display: flex;
    align-items: flex-start;

    &__ico {
        max-width: 38px;
        min-width: 38px;
        height: auto;
        margin-right: 18px;

        @include mediaPhone {
            margin-right: 15px;
            max-width: 32px;
            min-width: 32px;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 1;
        color: #2D58A4;
        margin-bottom: 10px;

        @include mediaTablet {
            font-size: 16px;
        }

        @include mediaPhone {
            font-size: 14px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: #0A182F;

        @include mediaPhone {
            font-size: 14px;
        }
    }
}

.block-manager {
    display: flex;
    flex-direction: column;

    &__img {
        max-width: 100%;
        width: 200px;
        height: auto;
        border-radius: 5px;
        margin-right: 25px;
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: 0.03em;
        color: #0A182F;
    }

    &__desc {
        margin-top: 7px;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        color: #758296;
    }

    &__info {
        margin-top: 12px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__btn {
        margin-top: 20px;
        align-self: flex-start;
    }
}

.hr_contacts {
    .block-manager {
        flex-direction: row;
    }
}
.employees_history {
    .block-review {
        flex-direction: row;
    }
}

@include mediaTablet {
    .account-main {
        margin: 34px 0 0 0;
        flex-direction: column;
    }
    .account-sidebar {
        padding: 0 25px 0 25px;
        margin: 0 0 25px 0;
        max-width: initial;
    }
    .account-accordion {
        .accordion__title {
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            .button {
                padding: 20px 0 20px 0;
            }
        }
        .accordion__content {
            display: none;
        }

        &.active {
            .accordion__content {
                display: flex;
                padding: 0 0 20px 0;
            }
            .accordion__title {
                .button {
                    padding: 20px 0 20px 0;
                }
            }
        }

        .button {
            margin: 0 0 20px 0;
            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
    .account-content {
        max-width: initial;
        min-height: 100px;
    }

    .products-estimates {
        &__title {
            display: none;
        }
    }
    .products-estimate {
        &:first-child {
            // display: none;
        }
    }
    .jobs-estimates {
        &__title {
            font-size: 16px;
            margin: 0 0 18px 0;
        }
    }

    .estimates-howto {
        &__list {
        }
        &__title {
            margin: 0 0 25px 0;
            font-size: 16px;
        }

        &__item {
            flex: 0 1 calc(33% - 28px);
            margin: 0px 28px 20px 0;
            .title {
                font-size: 22px;

                svg {
                    margin: 0 0 0 25px;
                }
            }
        }
    }

    .estimates-manager {
        &__tabs-content {
            .tab-content {
                margin: 24px 0 0 0;
                .title {
                    font-size: 22px;
                }

                .radio__label {
                    font-size: 14px;
                }
            }

            .new-tab {
                p {
                    font-size: 12px;
                }
                input {
                    font-size: 14px;
                }
            }
        }
        &__tab {
            padding: 34px 10px;
            font-size: 22px;
            svg {
                width: 18px;
                height: 18px;
                margin: 0 10px 0 0;
            }
        }
    }

    .loyalty {
        .title {
            margin: 0 0 27px 0;
            font-size: 22px;
            span {
            }
            a {
                font-size: 14px;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__programm {
            padding: 25px 30px 30px 30px;
        }

        &__programm-title {
            font-size: 16px;
        }

        &__programm-percentage {
            span {
                font-size: 22px;
                &.active {
                    font-size: 60px;
                }
            }
        }
    }

    .progress {
        p {
            // margin: 0 0 20px 0;
            font-size: 14px;
        }

        &__values {
            margin: 10px 0 0 0;
            font-size: 12px;
        }
    }
}

@include mediaPhone {
    .account-main {
        margin: 30px 0 0 0;
    }

    .account-accordion {
        .accordion__title {
            .button {
                padding: 18px 0 18px 0;
            }
        }
        .accordion__content {
        }

        &.active {
            .accordion__content {
                padding: 0 0 18px 0;
            }
            .accordion__title {
                .button {
                    padding: 18px 0 18px 0;
                }
            }
        }

        .button {
            margin: 0 0 18px 0;
            &:last-child {
                margin: 0 0 0 0;
            }
        }
    }
    .order-history {
        .period {
            margin: 0 0 20px 0;
            flex-direction: column;
            align-items: flex-start;
            &__between {
                margin: 0;
            }
        }
        .dropdown {
            width: 100%;
            margin: 0 0 10px 0;
            &__input-container {
                max-width: initial;
            }
        }
        .status {
            margin: 0 0 25px 0;
            flex-wrap: wrap;
            .checkbox {
                margin: 0 0 10px 0;
                // flex: 1;
                flex-basis: 50%;
            }
        }

        .list {
            &__item {
                font-size: 12px;
                &:first-child {
                    font-size: 14px;
                }
            }
        }
    }

    .order-detail {
        .title {
            margin: 0 0 8px 0;
            font-size: 18px;
        }

        .summary {
            flex-direction: column;
            align-items: flex-start;
            margin: 0 0 20px 0;

            &__item {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    .loyalty {
        .title {
            margin: 0 0 20px 0;
            font-size: 20px;
            flex-direction: column;
            align-items: flex-start;

            .button {
                margin-top: 10px;
            } 
        }
        &__programm {
            padding: 20px;
        }

        &__programm-title {
            font-size: 14px;
        }

        &__programm-percentage {
            span {
                font-size: 18px;
                &.active {
                    font-size: 40px;
                }
            }
        }
    }

    .progress {
        p {
            margin: 0 0 10px 0;
            font-size: 14px;
        }
    }

    .estimates-actions {
        flex-wrap: wrap;
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
    }
    .estimates-action {
        width: 40%;
        justify-content: flex-start;
        margin: 0 0 10px 0;

        &:last-child {
            margin: 0 0 10px 0;
        }
    }

    .estimates-list {
        margin: 0 0 20px 0;
    }

    .estimate {
        padding: 15px 0 0 0;
        flex-wrap: wrap;
        flex-direction: column;
        &:first-child {
            display: none;
        }

        &__col {
            font-size: 12px;
            flex-basis: 100%;
            margin: 0px 0 8px 0;
        }
        &__date {
            max-width: initial;
            .title {
                display: inline-block;
            }
        }
        &__sum {
            max-width: initial;
            justify-content: flex-start;
            .title {
                display: inline-block;
            }
        }
    }

    .estimates-howto {
        &__list {
        }
        &__title {
            margin: 0 0 20px 0;
            font-size: 15px;
        }

        &__item {
            flex: 0 1 calc(50% - 12px);
            margin: 0px 12px 10px 0;
            .title {
                margin: 0 0 5px 0;
                font-size: 18px;

                svg {
                    fill: #d9e0ec;
                    margin: 0 0 0 20px;
                }
            }
        }
    }

    .estimates-manager {
        &__tabs {
            flex-wrap: initial;
            flex-direction: column;
            margin: 0;
        }

        &__tab {
            margin: 0 0 10px 0;
            max-width: initial;
            padding: 22px 0;
            font-size: 18px;
            svg {
                width: 12px;
                height: 12px;
            }
        }
        &__tabs-content {
            margin: 0;

            .tab-content {
                margin: 15px 0 0 0;
                .title {
                    font-size: 18px;
                }
            }

            .button {
                padding: 11px 0;
                min-width: initial;
                width: 100%;
            }
        }
    }

    .products-estimate {
        padding: 15px 0;
        flex-wrap: wrap;
        &:first-child {
            display: none;
        }

        .col {
            .title {
                display: inline;
            }
        }

        &__code {
            max-width: initial;
            margin: initial;
            flex: initial;
            font-size: 12px;
            margin: 0 0 3px;
        }

        &__description {
            max-width: initial;
            order: -1;
            flex: 0 1 90%;
            width: 90%;
            margin: 0 0 10px 0;
        }
        &__number {
            flex: initial;
            display: none;
        }
        &__sum {
            flex: initial;
            margin: -20px 0 0 0;
            font-size: 16px;
        }
        &__unit {
            flex: 0 1 100%;
            max-width: initial;
            text-align: initial;
            margin: initial;
            font-size: 12px;
            margin: 0 0 3px;
        }
        &__count {
            flex: initial;
            max-width: initial;
            text-align: initial;
            font-size: 12px;
        }
        &__delete-button {
            top: -30px;
            right: -12px;
        }
    }

    .jobs-estimates {
        &__title {
            justify-content: space-between;
            font-size: 14px;
            padding: 0 0 12px 0;
            margin: 0 0 14px 0;
            border-bottom: 1px solid #d9e0ec;
        }
    }
    .jobs-estimate {
        flex-wrap: wrap;
        padding: 15px 0;
        &:first-child {
            display: none;
        }
        .col {
            .title {
                margin: 0 0 5px 0;
            }
        }
        .product {
            &__buy-info-count {
                justify-content: space-between;
            }
        }
        &__number {
            display: none;
        }
        &__description {
            max-width: initial;
            flex-basis: 100%;
            margin: 0 0 10px 0;
            span {
                display: block;
            }
        }
        &__count {
            flex: 0 0 48%;
            margin: 0 0 15px 0;
            max-width: initial;
            span {
                display: block;
            }
        }

        &__price {
            margin: 0 0 15px 0;
            flex: 0 0 48%;
            // margin: 0 0 0 12px;
            max-width: initial;
            span {
                display: block;
            }
        }

        &__sum {
            margin: 0 0 0 auto;
        }

        &__delete-button {
            position: static;
            transform: initial;
        }
    }
}

.mslistorders-form {
    cursor: pointer;

    .mslistorders-action {
        display: none;
    }
}

.outlay-works-prototype {
    margin: 0 60px 0 0;

    &::after {
        content: '';
        flex: 1;
        max-width: 100px;

        @include mediaPhone {
            display: none;
        }
    }
}