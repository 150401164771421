@import "variables", "mixin";
*,
*:after,
*:before {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}
body {
    font-size: 16px;
    font-family: "Gilroy", serif;
}

img {
    max-width: 100%;
}
a,
a:visited {
    color: #2D58A4;
}
a.active,
a:active,
a:hover {
    color: #D4343C;
}
a.active svg,
a:active svg,
a:hover svg {
    fill: #D4343C;
}
svg {
    overflow: visible;
}
h1,
h2,
h3,
h4,
p {
    margin: 0;
    padding: 0;
}

p {
    line-height: 1.5;
}
ol {
    padding: 0 0 0 30px;
}
ol li {
    padding: 0 0 0 14px;
}
ol li::marker {
    font-weight: bold;
}
ul {
    margin: 0;
    padding: 0 0 0 1.2rem;
    list-style-type: square;
}
li {
    padding: 0;
    margin: 0 0 1rem 0;
}
.bg-blue {
    background: #fbfcff;
}

.red {
    color: #D4343C;
}
.uppercase {
    text-transform: uppercase;
}
.d-none {
    display: none;
}
a {
    text-decoration: none;
}
[class$="ico"] {
    width: 18px;
    height: 18px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}
main {
    flex-grow: 1;
}
.lock {
    overflow: hidden;
    .wrapper {
        height: initial;
    }
}
.two-in-row {
    display: flex;
    justify-content: space-between;
    @include mediaPhone {
        flex-wrap: wrap;
    }
    & > * {
        width: 49%;
        @include mediaPhone {
            width: 100%;
        }
    }
}
.two-one-in-row {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 49%);
    grid-column-gap: 2%;
    grid-row-gap: 20px;
}
.sign {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    border: none;
}
.show-more_wrap {
    min-height: 75px;
    transition: min-height ease 0.3s;
    overflow: hidden;
}
.show-more_wrap.active {
    min-height: 100px;
}
.show-more_wrap .dottes {
    display: contents;
}
.show-more_wrap.active .dottes {
    display: none;
}
.show-more_wrap.active .cutter {
    display: contents;
}
.show-more_wrap .cutter {
    display: none;
}
.scroll_top {
    position: fixed;
    bottom: 10%;
    right: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(226, 8, 19, 0.6);
    border-radius: 100px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 600;
    &.active {
        visibility: visible;
        opacity: 1;
        transition: all 0.2s linear;
    }
    svg {
        width: 100%;
        height: 100%;
        fill: #fff !important;
        transform: rotate(90deg);
        flex-shrink: 0;
    }

    &:active,
    &:hover {
        background: #D4343C;

        svg {
            fill: #fff;
        }
    }
}

.map {
    display: block;
    margin: 25px 0 12px 0;
}
main section:first-child {
    border-top: 1px solid #d9e0ec;
}
.home-page section:first-child {
    border: 0;
}

.cards-section {
    padding: 73px 0 85px 0;

    @include mediaTablet {
        padding: 57px 0 93px 0;
    }
    @include mediaPhone {
        padding: 38px 0 73px 0;
    }
}

.calc-section {
    padding: 73px 0 85px 0;

    @include mediaTablet {
        padding: 57px 0 93px 0;
    }
    @include mediaPhone {
        padding: 38px 0 73px 0;
    }
}

.about-section, .vendors-section, .project-section {
    padding: 73px 0 85px 0;

    @include mediaTablet {
        padding: 57px 0 50px 0;
    }
    @include mediaPhone {
        padding: 38px 0 50px 0;
    }
}

.serv-section {
	background: #FBFCFF;
    padding: 71px 0 85px 0;
    position: relative;
    z-index: 1;

    @include mediaTablet {
        padding: 57px 0 50px 0;
    }

    @include mediaPhone {
        padding: 38px 0 50px 0;
    }

    .container {
        display: flex;
        justify-content: space-between;

        @include mediaTablet {
            flex-direction: column;
        }
    }

    &__text {
        max-width: 465px;
        margin-right: 40px;

        @include mediaTablet {
            max-width: 100%;
            margin-right: 0;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 22px;

        @include mediaTablet {
            margin-bottom: 10px;
        }

        @include mediaPhone {
            font-size: 22px;
        }
    }

    &__desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        margin: 0;

        @include mediaTablet {
            font-size: 16px;
        }

        @include mediaPhone {
            font-size: 14px;
        }
    }

    &__contacts {
        border-top: 1px solid #D2D7E4;
        margin-top: 40px;
        padding-top: 40px;

        @include mediaTablet {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            padding-top: 30px;
        }

        @include mediaPhone {
            margin-top: 20px;
            padding-top: 20px;
        }
    }

    &__tel {
        display: inline-block;
        font-weight: 600;
        font-size: 45px;
        color: #2D58A4;
        text-decoration: dashed;

        @include mediaTablet {
            font-size: 35px;
        }

        @include mediaPhone {
            font-size: 20px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        margin-top: 20px;

        @include mediaTablet {
            margin-top: 0;
        }

        .social-item {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #BEC7D7;
            text-decoration: none;
            margin-right: 10px;

            @include mediaTablet {
                width: 44px;
                height: 44px;
                margin-right: 0;
                margin-left: 10px;
            }

            &:hover {
                background-color: #2D58A4;
            }

            svg {
                fill: #fff;
                width: 18px;
                height: 18px;
            }
        }
    }

    &__form {
        width: 575px;

        @include mediaTablet {
            width: 100%;
            margin-top: 30px;
        }

        .form-row {
            display: flex;
            
            @include mediaPhone {
                flex-direction: column;
            }
        }

        .form-col {
            margin-right: 10px;
            flex: 1;
            margin-bottom: -10px;

            @include mediaPhone {
                margin: 0;
            }

            input {
                margin-bottom: 10px;
            }
        }

        textarea {
            flex: 1;
            resize: none;

            @include mediaPhone {
                min-height: 98px;
            }
        }

        .checkbox {
            margin-top: 20px;

            @include mediaTablet {
                width: 47%;
                display: inline-block;
            }

            @include mediaPhone {
                width: 100%;
            }
        }

        .button {
            margin-top: 25px;
            width: 100%;

            @include mediaTablet {
                width: 52%;
                display: inline-block;
            }

            @include mediaPhone {
                width: 100%;
            }
        }
    }
}

.catalog {
    .cards-section {
        padding: 73px 0 0 0;

        &:last-child {
            padding: 73px 0 85px 0;
            margin: 0;
        }

        @include mediaTablet {
            padding: 57px 0 0 0;

            &:last-child {
                padding: 57px 0 93px 0;
            }
        }
        @include mediaPhone {
            padding: 38px 0 0 0;
            margin: 0 0 30px 0;
            &:last-child {
                padding: 38px 0 73px 0;
            }
        }
    }
}

// .latest.section,
// .articles.section {
//     padding: 73px 0 0 0;

//     @include mediaTablet {
//         padding: 57px 0 0 0;
//     }
//     @include mediaPhone {
//         padding: 38px 0 0 0;
//     }
// }

.news.section {
    margin: 30px 0 0 0;
}
.home-page {
    .news.section {
        margin: 0;
    }
}
.news.page.section {
    margin: 0;
}
.page.section {
    padding: 0;
}
.section {
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 47px 0;
        &-item {
        }
    }
    &__top-header {
        margin: 0;
        font-family: $secondaryFont;
        font-weight: 700;
        font-size: 32px;
        color: #0A182F;
    }

    &__header {
        font-family: $secondaryFont;
        font-weight: 700;
        margin: 35px 0 0 0;
        font-size: 32px;
        color: #0a182f;
    }
    &__subheader {
        margin: 10px 0 0 0;
        font-weight: normal;
        font-size: 14px;
        color: #0a182f;
    }
    &__top-header {
        white-space: nowrap;
        margin: 0;
    }
    &__top-sub-header {
    }
    &__top-divider {
        height: 1px;
        // flex-basis: 100%;
        width: 100%;
        max-width: 824px;
        margin: 5px 45px 0 45px;
        background: #d9e0ec;
    }

    @include mediaTablet {
        &__top-header {
            font-size: 32px;
        }
        &__header {
            font-size: 32px;
            margin: 18px 0 5px 0;
        }
        &__top-divider {
            margin: 5px 30px 0 30px;
        }
    }
    @include mediaPhone {
        &__top {
            display: block;
        }
        &__top-header {
            font-size: 22px;
            margin: 0 0 5px;
        }
        &__header {
            font-size: 22px;
            margin: 12px 0 5px 0;
        }

        &__top-divider {
            display: none;
        }
    }
}

main .section {
    &:last-child {
        padding-bottom: 85px;

        @include mediaTablet {
            padding-bottom: 64px;
        }
        @include mediaPhone {
            padding-bottom: 45px;
        }
    }
}

main .contacts.section {
    &:last-child {
        padding-bottom: 105px;

        @include mediaTablet {
            padding-bottom: 84px;
        }
        @include mediaPhone {
            padding-bottom: 65px;
        }
    }
}

main .promo.section {
    &:last-child {
        padding-bottom: 85px;

        @include mediaPhone {
            padding-bottom: 65px;
        }
    }
}

.container {
    max-width: 1180px;
    margin: 0 auto;
    @include media(1200px) {
        padding: 0 15px;
    }
    @include media(1000px) {
        padding: 0 32px;
    }
    @include mediaPhone {
        padding: 0 15px;
    }
}

.link-with-icon {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    font-family: $secondaryFont;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    text-decoration-line: underline;
    color: #0A182F;

    &__ico {
        position: absolute;
        width: 11px;
        height: 9px;
        fill: #0A182F;
        right: -15px;
        top: 5px;
        transition: right 0.1s linear;
    }

    &:hover & {
        &__ico {
            right: -18px;
            transition: right 0.1s linear;
        }
    }

    @include mediaPhone {
        font-size: 14px;
    }
}

.in-stock-table {
    overflow-x: auto;
    @include scrollbar(#0a182f, #edf2fb, 0, 10px);
    table {
        min-width: 704px;
        color: #0a182f;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        border: 1;
        table-layout: fixed;
        thead th {
            font-family: $secondaryFont;
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0.03em;
            padding: 0 0 25px 0;
        }
        thead th:nth-child(2) {
            padding-left: 23px;
        }
        tbody tr {
            border-bottom: 1px solid #d9e0ec;
        }

        tbody tr:first-child td {
            font-family: $secondaryFont;
            font-weight: 600;
            font-size: 16px;
        }

        tbody tr td {
            font-size: 14px;
            padding: 10px 0 12px 0;
        }
        tbody tr td:nth-child(3) {
            border-right: 1px solid #d9e0ec;
        }
        tbody tr td:nth-child(4) {
            padding-left: 23px;
        }
    }
    p {
        margin: 25px 0 0 0;
        font-size: 12px;
        color: #0a182f;

        @include mediaTablet {
            margin: 25px 0 20px 0;
        }
    }
}

.section:not(.baner) {
    .splide {
        &__arrows {
            @include mediaTablet {
                display: none;
            }
        }

        &__pagination {
            position: static;
            width: 100%;
            align-items: center;
            justify-content: center;
            transform: none;
            margin-top: 25px;

            &__page {
                background: #2d58a4;

                &.is-active {
                    background: #2d58a4;
                }
            }
        }
    }
}

@include mediaTablet {
   
    .section__top {
        margin: 0 0 35px 0;
    }
   
}
@include mediaPhone {
    
    .section__top {
        margin: 0 0 27px 0;
    }
   
}

.error_404 {
    padding-top: 60px;
    text-align: center;

    p {
        margin: 20px auto;
        max-width: 340px;
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: center;

        .button {
            margin: 0 7px;
            padding-left: 25px;
            padding-right: 25px;
            &.button--primary {
                color: #ffffff;
            }

            @include mediaPhone {
                padding: 0 15px;
                height: 44px;
                line-height: 42px;
            }
        }
    }
    
}
  
.text-center {
    text-align: center;
}

.product-item-gallery .splide__slide img {
    object-fit: contain;
}

