@import 
"variables",
"mixin";
.popup-tooltip-trigger {
    position: relative;

    &:hover > svg {
        fill: #D4343C;
    }
    &.active:hover > svg {
        fill: #2D58A4;
    }
    &.active .popup-tooltip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s linear;

        & > svg {
            fill: #2D58A4;
        }

        &__title {
            text-align: left;
            background: #f8faff;
            border-bottom: 1px solid #d9e0ec;
        }

        &__arrow {
            border-bottom-color: #d9e0ec;
        }

        &__arrow2 {
            border-bottom-color: #f8faff;
        }

        &__content-main {
            display: block;
        }
    }
    &:hover .popup-tooltip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s linear;
    }
}

.popup-tooltip {
    position: absolute;

    top: 35px;
 
    background: #ffffff;
    border: 1px solid #d9e0ec;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear;
    z-index: 100;
    cursor: default;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    &__arrow {
        position: absolute;
        top: 1px;
        
        border: 10px solid transparent;
        border-bottom-color: #d9e0ec;
    }

    &__arrow2 {
        position: absolute;
        top: 2px;
        border: 10px solid transparent;
        border-bottom-color: #fff;
    }

    &__title {
        font-size: 12px;
        color: #0a182f;
        padding: 15px;
        white-space: nowrap;
    }

    &__content {
    }
    &__content-main {
        position: relative;
        padding: 15px;
        display: none;
        min-width: 193px;
    }
}

[data-direction="center"] .popup-tooltip {
    left: 50%;
    transform: translateX(-50%);
    &__arrow,
    &__arrow2 {
        left: 50%;
        transform: translate(-50%, -100%);
    }
}
[data-direction="right"] .popup-tooltip {
    right: -20px;
    transform: translateX(0);
    &__arrow,
    &__arrow2 {
        right: 0;
        transform: translate(-90%, -100%);
    }
}
[data-direction="left"] .popup-tooltip {
    left: -20px;
    transform: translateX(0);
    &__arrow,
    &__arrow2 {
        left: 0;
        transform: translate(-10%, -100%);
    }
}
