@import 
"variables",
"mixin";
.socials {
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        margin: 0 10px 0 0;
    }
 

    &__icons {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin: 0 10px 0 0;
            // background: #ffffff;
            border-radius: 50%;

            @include mediaTablet {
                margin: 0 5px 0 0;
            }
        }
        a:last-child {
            margin: 0;
        }

        .social {
            &__ico {
                flex-shrink: 0;
                &:hover {
                }
            }
        }
    }
}

 