@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Regular.eot');
	src:
    url('../fonts/Gilroy-Regular.woff') format('woff'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype'),
    url('../fonts/Gilroy-Regular.otf') format('opentype'),
    url('../fonts/Gilroy-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Medium.eot');
	src:
    url('../fonts/Gilroy-Medium.woff') format('woff'),
    url('../fonts/Gilroy-Medium.ttf') format('truetype'),
    url('../fonts/Gilroy-Medium.otf') format('opentype'),
    url('../fonts/Gilroy-Medium.svg') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-SemiBold.eot');
	src:
    url('../fonts/Gilroy-SemiBold.woff') format('woff'),
    url('../fonts/Gilroy-SemiBold.ttf') format('truetype'),
    url('../fonts/Gilroy-SemiBold.otf') format('opentype'),
    url('../fonts/Gilroy-SemiBold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-Bold.eot');
	src:
    url('../fonts/Gilroy-Bold.woff') format('woff'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype'),
    url('../fonts/Gilroy-Bold.otf') format('opentype'),
    url('../fonts/Gilroy-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('../fonts/Gilroy-ExtraBold.eot');
	src:
    url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
    url('../fonts/Gilroy-ExtraBold.ttf') format('truetype'),
    url('../fonts/Gilroy-ExtraBold.otf') format('opentype'),
    url('../fonts/Gilroy-ExtraBold.svg') format('svg');
	font-weight: 800;
	font-style: normal;
    font-display: swap;
}