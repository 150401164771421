@import "variables", "mixin";

.contacts {
    .splide {
        
        &__list {
            width: initial;
        }
        &__arrow {
            background: #ffffff;
            border: 1px solid #d9e0ec;
        }
        &__arrow--next {
            right: -155px;
        }
        &__arrow--prev {
            left: -155px;
        }
        &__arrow:hover {
            border: 1px solid #2D58A4;
        }
        &__pagination {
            bottom: -72px;
        }
        &__pagination__page {
            background: #2D58A4;
        }

        &__bullet {
            background: #2D58A4;
        }
    }
    .splide__track > .splide__list > .splide__slide {
        justify-content: flex-start;
    }
}

@include media(1420px) {
    .contacts {
        .splide {
            &__arrow--next {
                right: -105px;
            }
            &__arrow--prev {
                left: -105px;
            }
        }
    }
}
@include media(1240px) {
    .contacts {
        .splide {
            &__pagination {
                display: flex;
            }

            &__arrows {
                display: none;
            }
        }
    }
}

@include mediaTablet {
    .contacts {
        .splide {
            &__pagination {
                display: flex;
                bottom: -62px;
            }

            &__arrows {
                display: none;
            }
        }
    }
}
