@import 
"variables",
"mixin";

.alphabet {
    margin: 44px 0 0 0;

    &__chars-row {
        display: flex;
    }

    &__chars-section {
        display: flex;
        flex-wrap: wrap;
    }

    &__chars-link {
        display: inline-block;
        margin: 0 10px 12px 0;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 24px;

        letter-spacing: 0.36em;

        color: #0a182f;

        &.active {
            color: #D4343C;
        }
        &.disabled:hover,
        &.disabled {
            color: #758296;
            cursor: default;
        }
    }
    
    .digit-chars {
        margin: 0 0 12px 36px;
    }
}

.alphabet {
    &__details {
        padding: 55px 55px 20px 55px;
        margin: 40px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #d9e0ec;
        border-radius: 3px;
    }

    &__details-item {
        flex: 0 1 calc(20% - 21px);
        margin: 0 0 30px 0;
    }
    &__details-item.active & {
        &__details-char {
            color: #D4343C;
        }
    }
    &__details-char:target {
        color: #D4343C;
    }
    &__details-item:last-child {
        margin: 0;
    }
    &__content {
    }
    &__details-content {
        display: block;
    }

    &__details-brands {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            margin: 0 0 10px 0;
            line-height: 1.5;
            font-size: 14px;
            color: #0a182f;

            display: inline-block;
            &:hover {
                color: #D4343C;
            }
        }
    }
    &__details-title {
        margin: 0 0 8px 0;

        svg {
            display: none;
            width: 10px;
            height: 10px;
            fill: #0a182f;
            margin: 0 0 0 auto;
        }
    }

    &__details-char {
        display: flex;
        align-items: center;
        width: 100%;
        font-family: $secondaryFont;
        font-weight: 600;
        font-size: 40px;
        color: #2D58A4;
    }

    &__details-names-count {
        display: none;
        margin: 0 0 0 8px;
        color: #758296;
    }
}
@include media(1100px) {
    .alphabet {
        &__details-item {
            flex: 0 1 calc(25% - 25px);
        }
        &__details-item:last-child {
            margin: 0 auto 30px 30px;
        }
    }
}
@include mediaTablet {
    .alphabet {
        margin: 25px 0 0 0;
        &__details {
            margin: 30px 0 0 0;
            padding: 20px 30px;
        }
        &__details-item {
            flex: 0 1 calc(33% - 25px);
        }
        &__details-char {
            font-size: 30px;
        }
    }
}

@include media(640px) {
    .alphabet {
        &__details-item:last-child {
            margin: 0 auto 30px 0;
        }
    }
}
@include mediaPhone {
    .alphabet {
        margin: 29px 0 0 0;
        &__chars {
            display: none;
        }
    }

    .alphabet {
        &__details {
            padding: 0;
            margin: 0;
            border: 0;
        }
        &__details-item {
            flex: 0 1 100%;

            margin: 0 0 10px 0;
            border-bottom: 1px solid #d9e0ec;

            &:first-child {
                padding-top: 10px;
                border-top: 1px solid #d9e0ec;
            }

            &.active & {
                &__details-content {
                    display: block;
                }
            }
        }

        &__details-char:target {
            color: #2D58A4;
        }
        &__details-item.active & {
            &__details-char {
                color: #D4343C;
            }
        }

        &__details-brands {
            a {
                margin: 0 0 8px 0;
                font-size: 14px;
            }
        }
        &__details-content {
            display: none;
        }

        &__details-title {
            cursor: pointer;

            svg {
                display: block;
            }
        }

        &__details-char {
            font-size: 18px;
        }

        &__details-names-count {
            display: block;
        }
    }
}
