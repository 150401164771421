.compare {
    &__top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 60px 0 0;

        .input {
            margin: 0 12px 5px 0;

            &:last-child {
                margin: 0;
                margin-bottom: 5px;
            }

         
            i {
                margin: 0 0 0 5px;
            }
        }
    }
}
.compare-cards {
    margin: 40px 0 0;
 
}

.compare-characteristics {
    margin: 50px 0 0;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-weight: 700;
            font-size: 32px;
            line-height: 1;
            color: #0A182F;
            margin-bottom: 5px;
        }

        .button {
            font-weight: 600;
        }
    }
    &__list {
        margin: 35px 0 0 0;
    }

    .delete-products-button {
        svg {
            width: 14px;
            height: 14px;
            margin: 0 0 0 10px;
        }
    }

    .sortby {
        margin: 0;
    }
}

.characteristic {
    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        padding: 10px 0;
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        border-top: 1px solid #d9e0ec;
    }
    &__title {
        color: #758296;
        visibility: hidden;
    }
    &__list.is-active & {
        &__title {
            visibility: visible;
        }
    }
    &__value {
        color: #0a182f;
        font-weight: 500;
    }
}
@include mediaTablet {
    .compare-characteristics {
        &__top {
            flex-wrap: wrap;

            .title {
                font-size: 24px;
            }

            .compare-sort {
                margin-top: 20px;
            }
        }
        &__list {
            margin: 25px 0 0 0;
        }
    }

    .characteristic {
        &__item {
            padding: 8px 0;
        }
    }
}

@include mediaPhone {
    .compare {
        &__top {
            margin: 28px 0 0 0;
            flex-direction: column;
            align-items: flex-start;
            .text-radio {
                margin: 0 0 5px 0;
            }
        }
    }
    .compare-characteristics {
        &__top {
            .title {
                font-size: 20px;
            }

            .compare-sort {
                margin-top: 15px;
            }

            .button {
                font-size: 10px;
                padding: 0;
            }
        }
        &__list {
            margin: 10px 0 0 0;
        }
    }
    .compare-cards {
        margin: 10px 0 0 0;
    }
    .characteristic {
        &__item {
            padding: 4px 0;
            font-size: 12px;
            border: none;
        }
    }
}

.js-compare-wrapper {
    display: none;

    &.active {
        display: block;
    }

    .in_compare {
        pointer-events: none;
    }
}

.compare .splide__list .comparison-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.comparison-table .comparison-corner.compare-characteristics__top {
    width: 100%;
}

a.comparison-link.active {
    background: none!important;
}

.comparison-table .field-price {
    font-weight: inherit!important;
}