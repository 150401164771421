@mixin mediaLaptop {
  @media screen and (max-width: 1360px) {
    @content;
  }
}
@mixin mediaTablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}
@mixin mediaPhone {
	@media screen and (max-width: 510px) {
		@content;
	}
}
@mixin media($width) {
	@media screen and (max-width: $width) {
	  @content;
	}
}
 

@mixin scrollbar($thumbcolor, $rangecolor, $borderradius, $width: auto, $height: auto) {
  scrollbar-color: $thumbcolor $rangecolor;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }
  &::-webkit-scrollbar-track {
    background-color: $rangecolor;
    border-radius: $borderradius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $thumbcolor;
    border-radius: $borderradius;
  }
}