@import "variables", "mixin";

.catalog-item {
	margin: 49px 0 0 0;

	.product {
		&__rating {
		}

		&__info-eye-rating {
			display: flex;
			align-items: center;
		}

		&__eye-page {
			display: flex;
			align-items: center;
			margin-left: 30px;
			color: #53A42D;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.5;

			svg {
				width: 18px;
				height: 18px;
				fill: none;
				stroke: #53A42D;
				stroke-width: 1.5px;
				margin-right: 8px;
			}
		}

		&__info {
			display: flex;
			margin: 27px 0 0 0;
		}
		&__info-item {
			flex: 1;

			&.m-t {
				margin-top: -47px;
			}
		}
		&__galery {
			width: 100%;
			max-width: 380px;
			margin: 0 20px 0 0;
		}

		&__description-container {
			width: 100%;
			max-width: 420px;
			margin: 0 20px 0 0;
		}

		&__description-item {
			padding: 10px 0;
			font-size: 14px;
		}
		&__description-item:first-child {
			border: 0;
		}
		&__description-item:last-child {
		}
		&__description-title {
			line-height: 1.5;
			color: #758296;
		}

		&__description-text {
			margin: 0;
			color: #000;
		}

		&__description-link {
			font-size: 14px;
			text-decoration: underline;

			color: #2D58A4;
		}

		&__actions {
			display: flex;
			flex-direction: column;
			padding: 32px 40px;
			width: 100%;
			background: #EEF7FF;
			border-radius: 5px;
		}

		&__actions-info {
			display: flex;
			flex-direction: column;
			border: 1px solid #D2D7E4;
			border-radius: 5px;
			padding: 32px 40px;
			margin-bottom: 15px;

			p {
				margin: 0 0 5px 0;
				font-weight: normal;
				font-size: 14px;
				
				span {
					font-weight: 500;
					line-height: 1.5;
					color: #0a182f;
				}
			}
			p:last-child {
				margin: 0;
			}
			.product-instock-link {
				margin: 0 0 10px 0;
				color: #2D58A4;
				font-size: 14px;
				text-decoration: underline;
			}

			&-header {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
		}

		&__buy {
			margin: 0;

			&-info-price {
				display: flex;
				align-items: flex-end;
				flex-direction: row;

				&-old {
					margin-left: 15px;
					font-weight: 400;
					font-size: 18px;
					margin-bottom: 5px;
				}

				&-sale {
					display: block;
					margin-top: 10px;
					margin-bottom: 20px;

					.val {
						display: inline-block;
						margin-left: 8px;
						height: 23px;
						background: #FFE3C1;
						border-radius: 3px;
						line-height: 23px;
						padding: 0 8px;
						font-weight: 800;
						font-size: 12px;
						color: #FFA031;
					}
				}
			}
		}

		&__buy-info-footer {
			display: flex;
			align-items: center;
			margin: 15px 0 0 0;
		}

		&__buy-info-price-actual {
			font-size: 30px;
		}
		&__actions-price {
			margin: 30px 0 0 0;
		}

		&__actions-price-value {
		}

		&__buy-button {
			margin-left: 10px;
			height: 50px;
			padding: 15px;

			svg {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}
		}

		&__actions-compare {
			margin: 0  0 30px 0;
			display: flex;
			align-items: center;

			a {
				font-size: 14px;
				color: #2D58A4;
				display: flex;
				align-items: center;

				&:hover,
				&.in_compare {
					color: #D4343C;

					svg {
						fill: #D4343C;
					}
				}

				svg {
					margin: 0 10px 0 0;
					width: 18px;
					height: 18px;
					fill: #2D58A4;
				}
			}

			a:first-child {
				margin: 0 19px 0 0;
			}

			.btn-fav {
				font-size: 14px;
				color: #2D58A4;
				display: flex;
				align-items: center;
				margin: 0 19px 0 0;
				cursor: pointer;

				&:hover,
				&.added {
					color: #D4343C;

					svg {
						fill: #D4343C;
					}
				}

				svg {
					margin: 0 10px 0 0;
					width: 18px;
					height: 18px;
					fill: #2D58A4;
				}
			}

			.comparison {
				a {
					margin-right: 0;
				}
			}
		}

		.rating {
			margin: 49px 0 0 0;
		}
	}

	.product-thumbnails {
		margin: 12px 0 0 0;
	}

	.product-item-thumbnails {
		.thumbnails-wrapper {
			width: 100%;
			max-width: 380px;
			padding: 0px 0 12px;
			overflow-x: scroll;
			@include scrollbar(#2D58A4, #D2D7E4, 3px, auto, 3px);
		}
		&__list {
			display: flex;
			flex-wrap: nowrap;
			padding: 0;
		}

		&__item {
			cursor: pointer;
			opacity: 0.4;
			margin: 0 10px 0 0;
		}
		&__item.active {
			border: 1px solid #d9e0ec;
			opacity: 1;
		}
	}
}

@include mediaTablet {
	.catalog-item {
		margin: 32px 0 0 0;
		.product {
			&__info {
				//flex-wrap: wrap;
				margin: 22px 0 0 0;

				&-item {
					&.m-t {
						margin-top: -40px;
					}
				}
			}

			&__galery {
				margin: 0 24px 0 0;
				max-width: 347px;
			}

			&__description-container {
				display: none;
			}

			&__actions {
				margin: 0;
				max-width: initial;
				padding: 25px;

				&-info {
					padding: 25px;
				}
			}

			&__actions-compare {
				justify-content: flex-end;

				a:first-child {
					margin: 0 20px 0 0;
				}
				
				a {
					font-size: 0;
				}

				.btn-fav {
					font-size: 0;
				}

				.comparison {
					a {
						margin-right: 0;

						svg {
							margin-right: 0;
						}
					}
				}
			}

			&__buy-info-price {
				&-actual {
					font-size: 24px;
				}

				&-old {
					font-size: 16px;
				}

				&-sale {
					font-size: 14px;
				}
			}

			&__buy-info-count {
				height: 44px;
			}

			&__buy-button {
				height: 44px;
				padding: 12px;
			}
		}
	}
}

@include mediaPhone {
	.catalog-item {
		margin: 27px 0 0 0;
		.product {
			&__info {
				flex-direction: column;
				margin: 17px 0 0 0;
				max-width: initial;

				&-item {
					&.m-t {
						margin-top: 23px;
					}
				}
			}

			&__galery {
				margin: 0;
				max-width: initial;
			}

			&__description-container {
				display: none;
			}

			&__actions {
				padding: 20px;
				margin: 30px 0 0 0;
			}
			&__actions-compare {
				display: none;
				margin: 15px 0 0 0;

				svg {
					width: 14px;
					height: 14px;
				}
			}
			.rating {
				margin: 32px 0 0 0;
			}

			&__eye-page {
				display: none
			}
		}
	}
}

.catalog-item .product__actions-compare .comparison a.comparison-remove {
	display: none;
}

.catalog-item .product__actions-compare .comparison.added a.comparison-remove {
	display: flex;
}

a.comparison-link {
	border-bottom: none!important;
}

a.comparison-link.in_compare {
	color: #D4343C;
	svg {
		fill: #D4343C;
	}
}

.card__general-info-ico.js-fav.added svg {
	fill: #D4343C;
}

.comparison-table .comparison-remove {
	margin: 0!important;
	padding: 0!important;
}

.catalog {
	.container {
		.container {
			padding: 0;
		}
	}
}