.accordion {
      &__content {
            display: none;
            .accordion__title {
                transition: all ease 0.3s;
                .title {
                    transition: all ease 0.3s;
                }
                &:hover {
                    border-color: #2D58A4;
                    .title {
                        color: #2D58A4;
                    }
                }
            }
      }
      &.active & {
            &__title {
            }
            &__content {
                  //display: block;
                 
            }
            &__ico {
                  transform: rotate(-90deg);
            }
      }

      &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
      }

      &__ico {
             
            transform: rotate(90deg);
      }

      &--serf {
            .accordion {
                  &__title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        border-top: 1px solid #D2D7E4;
                        border-bottom: 1px solid #D2D7E4;
                        padding: 30px 0;
            
                        @include mediaTablet {
                              padding: 20px 0;
                        }
            
                        @include mediaPhone {
                              padding: 15px 0;
                        }
            
                        .title {
                              font-weight: 600;
                              font-size: 18px;
                              line-height: 1.5;
                              color: #0A182F;
            
                              @include mediaTablet {
                                    font-size: 16px;
                              }
            
                              @include mediaPhone {
                                    font-size: 14px;
                              }
                        }
            
                        .desc {
                              margin-top: 5px;
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 1.5;
                              color: #758296;
                              margin-bottom: 0;
                        }
                  }
            
                  &__btn {
                        position: relative;
                        background-color: #fff;
                        width: 44px;
                        min-width: 44px;
                        height: 44px;
                        border: 1px solid #D2D7E4;
                        border-radius: 50%;
                        cursor: pointer;
                        padding: 0;
                        transition: border-color 0.3s, background-color 0.3s;
                        margin-left: 20px;
            
                        &::before {
                              content: "";
                              display: inline-block;
                              width: 2px;
                              height: 11px;
                              background-color: #000;
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              transition: opacity 0.3s, background-color 0.3s;
                        }
            
                        span {
                              display: inline-block;
                              width: 11px;
                              height: 2px;
                              background-color: #000;
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              transition: background-color 0.3s;
                        }
                  }
            
                  &__ico {
                        transform: rotate(90deg);
                  }
            
                  &__content {
                        display: none;
                        padding: 25px 0;
            
                        @include mediaTablet {
                              padding: 20px 0;
                        }
            
                        @include mediaPhone {
                              padding: 15px 0;
                        }
            
                        .tabs {
                              margin: 0;
            
                              &__caption {
                                    margin: 0;
            
                                    li {
                                          font-weight: 600;
                                          font-size: 16px;
                                          line-height: 1.5;
                                          color: #0A182F;
                                          margin-right: 30px;
                                          cursor: pointer;
                                          width: auto;
                                          margin: 0;
                                          margin-right: 30px;
            
                                          @include mediaTablet {
                                                font-size: 14px;
                                                margin-right: 30px;
                                          }
            
                                          @include mediaPhone {
                                                font-size: 14px;
                                                margin-right: 15px;
                                          }
            
                                          &:not(:last-child) {
                                                margin: 0;
                                                margin-right: 30px;
            
                                                @include mediaTablet {
                                                      font-size: 14px;
                                                      margin-right: 30px;
                                                }
                  
                                                @include mediaPhone {
                                                      font-size: 14px;
                                                      margin-right: 15px;
                                                }
                                          }
            
                                          &.active {
                                                color: #2D58A4;
                                          }
                                    }
                              }
            
                              &__content {
                                    margin: 0;
                                    padding-top: 15px;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: #758296;
            
                                    @include mediaTablet {
                                          font-size: 14px;
                                    }
            
                                    @include mediaPhone {
                                          padding-top: 10px;
                                    }
            
                                    ul {
                                          li {
                                                margin-bottom: 5px;
                                          }
                                    }
                              }
                        }

                        .button {
                              margin-top: 20px;

                              @include mediaPhone {
                                    font-size: 14px;
                              }
                        }
                  }
            }

            &.active {
                  & > .accordion {
                        &__title {
                              border-bottom-color: #2D58A4;
      
                              .title {
                                    color: #2D58A4;
                              }
                              
                              & > .accordion__btn {
                                  background-color: #2D58A4;
                                  border-color: #2D58A4;
          
                                  &::before {
                                        background-color: #fff;
                                        opacity: 0;
                                  }
          
                                  span {
                                        background-color: #fff;
                                  }
                            }
                        }
      
                        
      
                        &__content {
                              display: block;
                        }
      
                        &__ico {
                              transform: rotate(-90deg);
                        }
                  }
            } 
      }
}

.accordion__content:has(.accordion--vacancy) {
    padding: 0 20px;
    .accordion__title {
        border-top: none;
    }
}



 