@import 
"variables",
"mixin";

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15.5px 25px;
    background: none;
    border: none;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    border-radius: 5px;
    cursor: pointer;

    @include mediaTablet {
        padding: 12px;
    }

    &--primary {
        color: #ffffff;
        border: 1px solid #2D58A4;
        background: #2D58A4;

        span {
            color: #ffffff;
        }

        svg {
            fill: #ffffff;
        }
    }
    &--small {
        max-width: 228px;
    }

    &--primary:active,
    &--primary.active,
    &--primary:hover {
        color: #ffffff;
        background: #D4343C;
        border: 1px solid #D4343C;
        span {
            color: #ffffff;
        }
        svg {
            fill: #fff;
        }
    }

    &--secondary {
        span {
            color: #2D58A4;
        }

        svg {
            fill: #2D58A4;
        }
    }
    &--secondary:active,
    &--secondary.active,
    &--secondary:hover {
        span {
            color: #D4343C;
        }

        svg {
            fill: #D4343C;
        }
    }

    &__ico {
        margin: 0 10px 0 0;
    }
    &__text {
        font-family: Gilroy;
        line-height: 1.5;
        color: #0a182f;
    }
    &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        padding: 10px;
        margin: 0 0 0 5px;
        font-family: Gilroy;
        font-weight: 500;
        font-size: 10px;
        text-align: center;
        border-radius: 50px;
        color: #ffffff;
        background: #2D58A4;
        padding: 0;
        line-height: 16px;
        text-align: center;
    }

    &--outline {
        border: 1px solid #2D58A4;
        border-radius: 5px;
        color: #2D58A4;
        line-height: 48px;
        padding: 0 25px;

        @include mediaTablet {
            line-height: 41px;
        }

        &:hover {
            background-color: #2D58A4;
            color: #fff;
        }
    }
}
.text-button {
    padding: 0;
    text-transform: initial;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    &--primary {
     
        color: #0a182f;
        span {
            color: #0a182f;
        }
        svg {
            fill: #0a182f;
        }
    }

    &--primary:active,
    &--primary.active,
    &--primary:hover {
        color: #D4343C;
        span {
            color: #D4343C;
        }

        svg {
            fill: #D4343C;
        }
    }
    &--secondary {
         color: #D4343C;
        span {
            color: #D4343C;
        }
        svg {
            fill: #D4343C;
        }
    }

    
    &--third {
        color: #2D58A4;
        span {
            color: #2D58A4;
        }
        svg {
            fill: #2D58A4;
        }
    }

    &--third:active,
    &--third.active,
    &--third:hover {
        text-decoration: underline;
         color: #2D58A4;
        span {
            color: #2D58A4;
        }

        svg {
            fill: #2D58A4;
        }
    }
}
 