@import 
"variables",
"mixin";
 
// Для галереи товара
.splide__track > .splide__list > .splide__slide {
    display: flex;
}

.product-item-thumbnails .splide__list {
    display: flex;
  
}
.splide--nav > .splide__track > .splide__list > .splide__slide {
    border: 0;
    opacity: 0.5;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
    border: 1px solid #d9e0ec;
    opacity: 1;
}
  