@import "variables", "mixin";

.contacts {
    &__list-container {
        background: #ffffff;
        border: 1px solid #d9e0ec;
        padding: 35px 50px;
        margin: 39px 0 0 0;
    }
    &__list {
    }

    &__title {
        margin: 0 0 25px;
        font-family: $secondaryFont;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0.03em;
        color: #0a182f;
    }

    &__items {
        display: flex;
        justify-content: space-between;

        &--wrap {
            flex-wrap: wrap;

            .contacts__item {
                flex: auto !important;
                width: 50%;
                margin-bottom: 20px !important;
            }
        }
    }
    &__col {
        flex: 1;
        margin: 0 50px 0 0;
        &:last-child {
            margin: 0;
        }
    }
    &__item {
        margin: 0 0 15px 0;
        min-height: 69px;
        &:last-child {
            margin: 0;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #0a182f;
        }
    }

    &__item-title {
        display: flex;
        align-items: center;
        margin: 0 0 2px 0;
        font-size: 16px;
        line-height: 1.5;
        color: #758296;

        svg {
            flex-shrink: 0;
            margin: 0 10px 0 0;
            width: 16px;
            height: 16px;
            fill: #758296;

            &.ico-mail {
                fill: none;
                stroke: #758296;
                stroke-width: 1.5px;
            }
        }
    }

    &__item-link {
        font-size: 14px;
        text-decoration: underline;
        color: #0a182f;

        &.phone {
            text-decoration: none;
        }
        &.phone:hover {
            text-decoration: underline;
        }
        &.director {
            color: #D4343C;
        }
        &.director:hover {
            text-decoration: none;
        }
        &:hover {
            color: #D4343C;
        }
    }
    &__item-text {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;

        color: #0a182f;
    }
}

.block-contacts {
    margin-bottom: 30px;

    @include mediaPhone {
        margin-bottom: 15px;
    }

    .contacts {
        &__title {
            font-weight: 700;
            font-size: 24px;

            @include mediaTablet {
                font-size: 18px;
            }
        }

        &__item {
            flex: 1;
            padding-right: 30px;
            margin: 0;

            @include mediaPhone {
                padding-right: 0;
                margin-bottom: 15px;
            }

            &-text {
                a {
                    font-weight: 600;
                    font-size: 24px;
                    color: #2D58A4;

                    @include mediaTablet {
                        font-size: 22px;
                    }

                    @include mediaPhone {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

#y_map {
    width: 100%;
    height: 400px;
}

@include mediaTablet {
    .contacts {
        &__list-container {
            padding: 25px;
            margin: 25px 0 46px 0;
        }
        &__list {
        }

        &__title {
            margin: 0 0 18px;
            font-size: 22px;
        }
        .splide__track {
        }
        &__items {
        }
        &__col {
            margin: 0 20px 0 0;
        }
        &__item {
            min-height: initial;

            p {
            }
        }

        &__item-title {
            font-size: 14px;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        &__item-text {
            font-size: 16px;
        }
    }

    .branch {
        &__slider {
        }
    }

    .middle-column {
        display: none;
    }
}

@include mediaPhone {
    .contacts {
        &__list-container {
            padding: 15px;
            margin: 15px 0 26px 0;
        }
        &__list {
        }

        &__title {
            margin: 0 0 15px;
            font-size: 18px;
        }
        .splide__track {
        }
        &__items {
            flex-direction: column;
        }
        &__col {
            margin: 0 0 10px;
        }
        &__item {
            margin: 0 0 15px 0;
          

            p {
            }
        }

        &__item-title {
            margin: 0 0 2px 0;
            font-size: 14px;

            svg {
            }
        }

        &__item-text {
            font-size: 14px;
        }
    }
}
