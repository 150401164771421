@import 
"variables",
"mixin";
.cards-section {
    .splide {
        &__track {
            padding: 10px 0 1px 0;
            margin: -10px 0 0 0;
        }

        &__slides {
        }

        &__arrows {
        }

        &__arrow {
            background: #ffffff;
            border: 1px solid #d9e0ec;
        }
        &__arrow:hover {
            border: 1px solid #2D58A4;
        }
        &__arrow svg {
        }
        &__pagination {
            display: none;
            bottom: -30px;
        }
        &__pagination__page {
            background: #2D58A4;
        }

        &__bullet {
            background: #2D58A4;
        }
    }
}
@include media(1240px) {
    .cards-section {
        .splide {
            &__pagination {
                display: flex;
            }

            &__arrows {
                display: none;
            }
        }
    }
}
